/*eslint-disable*/
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi.js";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import BanglesContact from "views/BanglesPage/Section/BanglesContact.js";
import BanglesCategory from "views/BanglesPage/Section/BanglesCategory.js";
import BanglesOverview from "views/BanglesPage/Section/BanglesOverview.js";
import BanglesComponent from "views/BanglesPage/Section/BanglesComponent.js";
import BanglesProject from "views/BanglesPage/Section/BanglesProject.js";
import BanglesFooter from "./Section/BanglesFooter.js"
import BanglesHeader from "./Section/BanglesHeader.js"
import HeaderLink from "./Section/BanglesHeaderLink.js"
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function BanglesPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    const classes = useStyles();
    const history = useHistory();

    const activeSession = sessionStorage.getItem("sessionData");

    const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
    // const headApi = `https://api.bangles.org.in/api/rest`;

    const handleLogOut = () => {
        const finalURL = localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
        fetch(finalURL, {
            method: "PUT",
            // headers: {
            //     "content-type": "application/json",
            //     accept: "application/json",
            // },
        })
            .then((response) => response.json())
            .then((response) => {
                //   console.log(response);
                if (response.error === true) {
                    localStorage.removeItem("sessionDate");
                    sessionStorage.removeItem("sessionData");
                    history.push("/")
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <div>
            <Helmet>
                <title>Impturkey.com</title>
                <meta name="description" content="Best Manufacture Comapnay" />
                <meta name="keywords" content="We are one of the top manufacturers and retailers in Maharastra & Karnataka" />
            </Helmet>
            <BanglesHeader
                brand="Home"
                links={<HeaderLink dropdownHoverColor="info" />}
                fixed
                color="info"
            // changeColorOnScroll={{
            //     height: 100,
            //     color: "info",
            // }}
            />
            <Parallax
                image={require("assets/img/baner.jpg").default}
                className={classes.parallax}
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                                <h1 style={{ color: "black" }}>
                                    Arfa Impex
                                    {/* <span className={classes.proBadge}>.Org</span> */}
                                </h1>
                                <h3 className={classes.title} style={{ color: "black" }}>
                                    Manufacture of : MEN'S JEANS
                                </h3>
                            </div>
                            <div className={classes.brand}>
                                <Link 
                                to="/bangles-sign-up"
                                ><Button color="info" round >Sign up</Button></Link> {" "}
                                {!activeSession ? <Button color="info" round 
                                href="/bangles-login" 
                                >log in</Button> : <Button color="info" round 
                                onClick={handleLogOut}
                                >log out</Button>}
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <BanglesComponent />
                <BanglesProject />
                <BanglesCategory />
                <BanglesOverview />
            </div>
            <BanglesContact />
            <BanglesFooter />
        </div>
    );
}
