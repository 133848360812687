import React from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Vodafone from "assets/img/assets-for-demo/ourClients/vodafone.jpg";
import Microsoft from "assets/img/assets-for-demo/ourClients/microsoft.jpg";
import Harvard from "assets/img/assets-for-demo/ourClients/harvard.jpg";
import Standford from "assets/img/assets-for-demo/ourClients/stanford.jpg";
import GoldPlated from "assets/img/assets-for-demo/Shirt3.jpeg";
import GoldPlat from "assets/img/assets-for-demo/Shirt4.jpeg";
import Gold from "assets/img/assets-for-demo/Shirt5.jpeg";

import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js";

const useStyles = makeStyles(overviewStyle);

export default function BanglesOverview() {
    const classes = useStyles();
    return (
        // <div className={classes.section}>
            <div className={classes.sectionTestimonials}>
                <Helmet>
                    {/* <title>Customer Review</title> */}
                    <meta name="description" content="whether it is cash payment or credit payment Accepted" />
                    <meta name="keywords" content="As soon as I saw this Product, I just can't describe it guys! Thank you for this work!" />
                </Helmet>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                            <h2 className={classes.title}>Customer Satisfaction</h2>
                            <h5 className={classes.description}>
                            We are committed for ensuring full customer satisfaction and customer assistance with respect to the products available on our website. We maintain complete Transparency in our bills, whether it is cash payment or credit payment.
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem md={4} sm={6} xs={12}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={12} xs={12}>
                                        <CardHeader image plain>
                                            <a href="#pablo">
                                                <img src={GoldPlated  } alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + GoldPlated   + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + GoldPlated   + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={12} xs={12}>
                                        <CardBody plain className={classes.alignLeft}>
                                            <h4 className={classes.cardTitle}>Gold plated party wear brass bangles</h4>
                                            <p className={classes.cardDescription}>
                                            We can offer you beautiful collection of bangles at prices that you cannot deny to buy it.
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem md={4} sm={6} xs={12}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={12} xs={12}>
                                        <CardHeader image plain>
                                            <a href="#pablo">
                                                <img src={GoldPlat} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + GoldPlat + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + GoldPlat + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={12} xs={12}>
                                        <CardBody plain className={classes.alignLeft}>
                                            <h4 className={classes.cardTitle}>Gold plated Fancy bangles</h4>
                                            <p className={classes.cardDescription}>
                                            Celebrate this festive season with us get unique, trendy and personalise traditional Bangles that you crave to wear it.
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem md={4} sm={6} xs={12}>
                            <Card plain profile>
                                <GridContainer>
                                    <GridItem md={3} sm={12} xs={12}>
                                        <CardHeader image plain>
                                            <a href="#pablo">
                                                <img src={Gold} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + Gold + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: "url(" + Gold + ")",
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem md={9} sm={12} xs={12}>
                                        <CardBody plain className={classes.alignLeft}>
                                            <h4 className={classes.cardTitle}>Daily wear gold plated bangles</h4>
                                            <p className={classes.cardDescription}>
                                            You will fall in love with our bangles finest quality metal and catchy designs.
                                            </p>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    {/* <div className={classes.ourClients}>
                        <GridContainer justify="center">
                            <GridItem md={3} sm={3}>
                                <img src={Vodafone} alt="vodafone" />
                            </GridItem>
                            <GridItem md={3} sm={3}>
                                <img src={Microsoft} alt="microsoft" />
                            </GridItem>
                            <GridItem md={3} sm={3}>
                                <img src={Harvard} alt="harvard" />
                            </GridItem>
                            <GridItem md={3} sm={3}>
                                <img src={Standford} alt="stanford" />
                            </GridItem>
                        </GridContainer>
                    </div> */}
                </div>
                
            </div>
        // </div>
    );
}
