import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import FormatQuote from "@material-ui/icons/FormatQuote";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Table from "components/Table/Table.js";
import BanglesHeader from "views/BanglesPage/Section/BanglesHeader.js"
import HeaderLink from "views/BanglesPage/Section/BanglesHeaderLink.js"
// import BanglesFooter from "./Section/BanglesFooter.js"

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import cardProfile1Square from "assets/img/bangle-size-guide.jpg";

const useStyles = makeStyles(testimonialsStyle);

export default function InshaBanglesSizeGuide({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <Helmet>
        <title>Insha Bangles Size Guide</title>
      </Helmet>
       <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
      }}
      />
      <div
        className={classes.testimonials + " " + classes.sectionImage}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Jeans And Shirt Size Guide</h2>
              <h5 className={classes.description}>
              This is a chart that will be extremely helpful for you to determine the bangle size that you need.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                  FIND YOUR JEANS' WAIST SIZE
                  </h5>
                  <Table  
                  tableData={[
                    [<span style={{fontWeight: "bold"}}>SIZE</span>, <span style={{fontWeight: "bold"}}>Waist (in)</span>, <span style={{fontWeight: "bold"}}>Seat (in)</span>, <span style={{fontWeight: "bold"}}>Thigh (in)</span>],
                      ["26", "26.5 - 27", "32.5 - 33", "20.5 - 21"],
                      ["27", "27.5 - 28", "33.5 - 34", "21 - 21.5"],
                      ["28", "28.5 - 29", "34.5 - 35", "21.5 - 22"],
                      ["29", "29.5 - 30", "35.5 - 36", "22 - 22.5"],
                      ["30", "30.5 - 32", "36.5 - 37", "22.5 - 23"],
                      ["31", "31.5 - 32", "37.5 - 38", "23 - 23.5"],
                      ["32", "32.5 - 33", "38.5 - 39", "23.5 - 24"],
                      ["33", "33.5 - 34", "39.5 - 40", "23 - 24.5"],
                      ["34", "34.5 - 35", "40.5 - 41", "24 - 25"],
                      ["35", "35.5 - 36", "41.5 - 42", "25 - 26"],
                      ["36", "36.6 - 37.5", "42.5 - 43.5", "26 - 27"],
                      ["38", "38.5 - 39.5", "44.5 - 45.5", "27 - 28"],
                      ["40", "40.5 - 41.5", "46.5 - 47.5", "27.25 - 28.25"],
                      ["42", "42.4 - 43.5", "48.5 - 49.5", "28 - 29"],
                      ["44", "44.5 - 45.5", "50.5 - 51.5", "28.75 - 29.75"],
                      ["46", "46.5 - 47.5", "52.5 - 53.5", "29.5 - 30.5"],
                      ["48", "48.5 - 49.5", "54.5 - 55.5", "30.25 - 31.25"],
                      ["50", "50.5 - 51.5", "56.5 - 57.5", "31 - 32"],
                      ["52", "52.5 - 53.5", "58.5 - 59.5", "31.75 - 32.75"],
                      ["54", "54.5 - 55.5", "60.5 - 61.5", "32.5 - 33.5"],
                      ["56", "56.5 - 57.5", "62.5 - 63.5", "33.5 - 34.5"],
                      ["58", "58.5 - 59.5", "64.5 - 65.6", "33.5 - 34.75"],
                      ["60", "60.5 - 61.5", "66.5 - 67.5", "34.75 - 36"] 
                      ]} />
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Steps to know your Jeans Size:</h4>
                  <h6 className={classes.cardCategory}>1. TAKE A JEANS AND PLACE IT ON A SCALE.<br /> 2. MEASURE THE INNER DIAMETER AS SHOWN IN THE PICTURE.<br /> 3. USE THE ABOVE SHOWN CHART TO DETERMINE YOUR JEANS SIZE</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                  Shirt Size
                  </h5>
                  <Table 
                  tableData={[
                    [<span style={{fontWeight: "bold"}}>SIZE</span>, <span style={{fontWeight: "bold"}}>CHEST SIZE ( inch )</span>, <span style={{fontWeight: "bold"}}>NECK SIZE ( inch )</span>],
                      ["XXXS", "30-32", "14"],
                      ["XXS", "32-34", "14.5"],
                      ["XS", "24-36", "15"],
                      ["S", "36-38", "15.5"],
                      ["M", "38-40", "16"],
                      ["L", "40-42", "17"],
                      ["XL", "42-44", "17.5"],
                      ["XXL", "44-46", "18.5"],
                      ["XXXL", "46-48", "19.5"]
                      ]} />
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Steps to know your Shirt Size:</h4>
                  <h6 className={classes.cardCategory}>1. TAKE A SHIRT AND PLACE IT ON A SCALE.<br /> 2. MEASURE THE INNER DIAMETER AS SHOWN IN THE PICTURE.<br /> 3. USE THE ABOVE SHOWN CHART TO DETERMINE YOUR SHIRT SIZE</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* <MyFooter /> */}
      </div>
  );
}