/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import SweetAlert from "react-bootstrap-sweetalert";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import BanglesHeader from "views/BanglesPage/Section/BanglesHeader";
import BanglesHeaderLink from "views/BanglesPage/Section/BanglesHeaderLink";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(profilePageStyle);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyleAlert = makeStyles(style);

export default function AddCart({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  const [userId, setUserId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [cardId, setCartId] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [cartShort, setCartShort] = React.useState("");

  const classes = useStyles();
  const history = useHistory();

  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeAlert = useStyleAlert();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const current = new Date();
  const dateCurrent = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  current.setDate(current.getDate() + 10);
  let dateEx = new Date(current).toLocaleDateString("de");

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj.id)[0];

        const list =
          localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${cList.id}?active=0`;
        const result = await fetch(list);
        const data = await result.json();
        // console.log(data);
        // setAddCart(data);
        if (data.error === "true") {
          setLoad(false);
          history.push("/error-page");
        } else {
          setLoad(true);
          const cLength = data.length === 1 ? data : "";
          if (cLength) {
            // console.log(cLength[0]);
            setCartShort(cLength[0]);
            setAddCart(data && data.length != 0 ? data : "");
          } else {
            setAddCart(
              data && data.length != 0 ? data : history.push("/error-page")
            );
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  const handleitemRemove = (c, submitted) => {
    setSubmitted(true);
  };

  const handleConfirm = () => {
    // console.log(cardId);
    axios
      .delete(
        localApi +
          `/cart/delete/byAdminAndCartId/${userObj.admin.id}/${cardId.id}`
      )
      .then((res) => {
        // console.log(res.data);
        // if (res.message === "Data Deleted Successfully!!! ") {
        //   setSubmittedConfirm(true)
        // window.location.reload(false);
        // }
        if (cartShort) {
          history.push(
            `/bangles-size-mm-list?catdesc=${
              cartShort && cartShort.cartCategory.title
            }&cat1desc=${
              cartShort && cartShort.cartParent.title
            }&Plating=Gold%20Plating`
          );
        }
      });
    setSubmittedConfirm(true);
  };

  const hideAlert = () => {
    setSubmitted(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  var totalCal = 0;
  var totalCalMix = 0;
  var totalSum = 0;

  return (
    <div>
      <Helmet>
        <title>Add Cart</title>
      </Helmet>
      {load ? (
        <>
          <BanglesHeader
            color="info"
            brand="Home"
            links={<BanglesHeaderLink dropdownHoverColor="info" />}
            fixed
            // changeColorOnScroll={{
            //     height: 200,
            //     color: "info",
            // }}
            {...rest}
          />
          <Parallax
            image={require("assets/img/ban.webp").default}
            filter="info"
            className={classes.parallax}
          ></Parallax>
          <div className={classNames(classeCart.main, classeCart.mainRaised)}>
            <div className={classeCart.container}>
              {submitted ? (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "100px" }}
                  title="Are you sure?"
                  onConfirm={handleConfirm}
                  onCancel={() => hideAlert()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                  cancelBtnCssClass={
                    classeAlert.button + " " + classeAlert.danger
                  }
                  confirmBtnText="Yes, delete it!"
                  cancelBtnText="Cancel"
                  showCancel
                >
                  That you want to remove this item from your cart?
                </SweetAlert>
              ) : null}

              {submittedConfirm ? (
                <SweetAlert
                  success
                  style={{ display: "block", marginTop: "100px" }}
                  title="Deleted!"
                  onConfirm={() => hideAlert()}
                  onCancel={() => hideAlert()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                >
                  Your item has been deleted!!
                </SweetAlert>
              ) : null}
              <Card plain>
                <CardBody plain>
                  <h3 className={classeCart.cardTitle}>My Cart</h3>
                  <Table
                    tableHead={[
                      <span className={classesTable.tableHead} key="sr">
                        Sr.
                        <br />
                        No.
                      </span>,
                      "",
                      <span className={classesTable.tableHead} key="des">
                        Description of Goods
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="ctn"
                      >
                        Carton
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="qty"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="pri"
                      >
                        Price
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="amt"
                      >
                        Amount
                      </span>,
                      "",
                    ]}
                    tableData={[
                      ...addCart.map((c, index) => [
                        <span className={classesTable.listNumber} key={c.id}>
                          {index + 1}
                        </span>,
                        <div className={classeCart.imgContainer} key={c.id}>
                          {/* Date: {c.createDate.slice(0, -19)} */}
                          <img
                            src={
                              c.cartImage[0].cropImageUrl +
                              "?id=" +
                              c.cartImage[0].imgId
                            }
                            className={classes.img}
                            style={{ height: "140px" }}
                          />
                        </div>,
                        <span key={c.id}>
                          <h6 className={classesTable.listDescription}>
                            {c.cartChild.map((f) =>
                              f.title === "Plating Gold Plated"
                                ? f.title.slice(8)
                                : ""
                            )}{" "}
                            {c.cartParent.title} {c.cartRawPrice.title} <br />
                            {c.cartCategory.title} with {c.cartPacking.type}
                            <br />
                            {c.readyGram * c.cartSets.pcs} Avg Gram of{" "}
                            {c.cartSets.setDesc}
                            <br />
                            Sets Price :{" "}
                            {c.gstCash === false
                              ? (c.cashPrice / c.cartBox.box).toFixed(2)
                              : (c.taxInclude / c.cartBox.box).toFixed(2)}
                            /- {c.gstCash === false ? "Cash" : "IncTax"}
                            <br />
                            Metal Rate : ₹{" "}
                            {c.cartRawPrice.price + c.cartRawPrice.different}/-
                            Advance : ₹
                            {Math.round(
                              c.metalGram *
                                c.cartSets.pcs *
                                c.cartBox.box *
                                c.productSize.grandTotal *
                                (c.cartRawPrice.price +
                                  c.cartRawPrice.different)
                            )}
                          </h6>
                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : c.productSize.s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : c.productSize.s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : c.productSize.s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : c.productSize.s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : c.productSize.s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x2 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : c.productSize.s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x4 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : c.productSize.s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x6 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : c.productSize.s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : c.productSize.s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : c.productSize.s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : c.productSize.s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : c.productSize.s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : c.productSize.s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : c.productSize.s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : c.productSize.s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : c.productSize.s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : c.productSize.s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : c.productSize.s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x3 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : c.productSize.s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x5 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : c.productSize.s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x7 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : c.productSize.s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : c.productSize.s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : c.productSize.s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : c.productSize.s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : c.productSize.s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : c.productSize.s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          {/* <span className={classesTable.listDescription}>{c && c.name ? c.name + ", " : ""} {c && c.mycomment ? c.mycomment : ""}</span> */}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.cartQuantity}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.productSize.grandTotal}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹
                          {c.gstCash === true
                            ? c.taxInclude.toFixed(2)
                            : c.cashPrice.toFixed(2)}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{Math.round(c.amount)}
                        </span>,
                        <Tooltip
                          id="close1"
                          title="Cart Remove"
                          placement="left"
                          classes={{ tooltip: classeCart.tooltip }}
                          key={c.id}
                        >
                          <Button
                            link
                            className={classeCart.actionButton}
                            onClick={() => {
                              handleitemRemove(c.id);
                              setCartId(c);
                            }}
                          >
                            <Close style={{ color: "red" }} />
                          </Button>
                        </Tooltip>,
                      ]),
                      [
                        "",
                        <span className={classesTable.listNumber} key={1}>
                          Cartoon Charges
                          <br />
                          Grand Total
                        </span>,
                        <span className={classesTable.listNumber} key={2}>
                          <br />
                          Advance - ₹{" "}
                          {Math.round(
                            addCart.reduce(
                              (a, b) =>
                                a +
                                b.metalGram *
                                  b.cartSets.pcs *
                                  b.cartBox.box *
                                  b.productSize.grandTotal *
                                  (b.cartRawPrice.price +
                                    b.cartRawPrice.different),
                              0
                            )
                          ) + "/-"}
                        </span>,
                        <span key={3}>
                          <br />
                          <span className={classesTable.headRight}>
                            Carton-
                            {addCart.reduce((a, b) => a + b.cartQuantity, 0)}
                          </span>
                        </span>,
                        <span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            Unit-
                            {addCart &&
                              addCart.reduce(
                                (a, b) => a + b.productSize.grandTotal,
                                0
                              )}
                          </span>
                        </span>,
                        "",
                        <span key={4}>
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {addCart.reduce(
                              (a, b) => a + b.cartPackCharge * b.cartQuantity,
                              0
                            ) + "/-"}
                          </span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {Math.round(
                              addCart.reduce(
                                (a, b) =>
                                  a +
                                  b.amount +
                                  b.cartPackCharge * b.cartQuantity,
                                0
                              )
                            ) + "/-"}
                          </span>
                        </span>,
                        "",
                      ],
                    ]}
                  />
                  <hr />
                  <Button
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                    color="info"
                    round
                    href="/cart-shipping-address"
                  >
                    Proceed Order
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
          <BanglesFooter />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
