/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
// import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import BanglesHeader from "./Section/BanglesHeader.js";
import HeaderLink from "./Section/BanglesHeaderLink.js";
import BanglesFooter from "./Section/BanglesFooter.js";
import logo from "assets/img/examples/apple-bangles-org-icon.png";
import kada1 from "assets/img/faces/kada1.jpg";
import polaBangles2 from "assets/img/faces/polaBangles2.jpg";
import fourPieces3 from "assets/img/faces/fourPieces3.jpg";
import sixPieces4 from "assets/img/faces/sixPieces4.jpg";
import sevenPieces5 from "assets/img/faces/sevenPieces5.jpg";
import eightPieces6 from "assets/img/faces/eightPieces6.jpg";
import twevelPieces7 from "assets/img/faces/twevelPieces7.jpg";
import twentyFourPieces8 from "assets/img/faces/twentyFourPieces8.jpg";
import axios from "axios";

const useStyles = makeStyles(productStyle);

export default function OfflineBanglesProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(1);

  const classes = useStyles();

  const activeSession = sessionStorage.getItem("sessionData");
  const headApi = `https://api.bangles.org.in/apio/rest`;
  // const imgApi = `https://imgstore.bangles.org.in`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setpage(page + 1);
    axios
      .get(
        headApi + `/products/limitList?userid=1&active=0&page=${page}&size=8`
      )
      .then((res) => setProdList([...prodList, ...res.data.ProductsData]));
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Offline Product List</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)."
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs"
        />
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        color="info"
        // changeColorOnScroll={{
        //     height: 100,
        //     color: "info",
        // }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg").default}
        // filter="info"
        className={classes.pageHeader}
      ></Parallax>
      <div className={classes.container}>
        <h5 className={classNames(classes.title, classes.textCenter)}>
          We make bangles that are loved by all age group ladies because our
          designs are unique and never get out of fashion. The raw material we
          use is of fine quality and also we give 6 months colour guarantee on
          our bangles. As we are a manufacturer, we give bangles at factory
          prices (discounted rates). We give an opportunity to customize the
          bangles according to your choice and taste so that you feel the joy of
          wearing the best bangles.
        </h5>
      </div>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classes.relatedProducts}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={kada1} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${kada1})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={polaBangles2} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${polaBangles2})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={fourPieces3} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${fourPieces3})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sixPieces4} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sixPieces4})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sevenPieces5} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sevenPieces5})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={eightPieces6} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${eightPieces6})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twevelPieces7} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twevelPieces7})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twentyFourPieces8} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twentyFourPieces8})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              Popular items Today:
            </h3>

            <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => (
                      <GridItem sm={6} md={3} key={prod.id}>
                        <Card product>
                          <CardHeader image>
                            <p
                              style={{
                                position: "absolute",
                                left: "90%",
                                top: "90%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <Tooltip
                                id="tooltip-tops"
                                title="Share to WhatsApp"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <a
                                  href={`https://api.whatsapp.com/send/?phone=${
                                    prod.user.whatsapp
                                  }&text=https://bangles.org.in/bangles-product?id=${
                                    prod.id
                                  }%20${
                                    prod.banglessizemm.description
                                  }%20${prod.plating.description.slice(
                                    8
                                  )}&app_absent=0`}
                                >
                                  {prod.design1.image1 !==
                                  imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : prod.design2.image2 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : prod.design3.image3 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : prod.design4.image4 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : prod.design5.image5 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : prod.design6.image6 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </Tooltip>
                            </p>

                            {prod.design1.img1active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design1.image1}
                                alt="..."
                              />
                            ) : prod.design2.img2active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design2.image2}
                                alt="..."
                              />
                            ) : prod.design3.img3active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design3.image3}
                                alt="..."
                              />
                            ) : prod.design4.img4active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design4.image4}
                                alt="..."
                              />
                            ) : prod.design5.img5active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design5.image5}
                                alt="..."
                              />
                            ) : prod.design6.img6active === 1 ? (
                              <img
                                src={imgApi + "" + prod.design6.image6}
                                alt="..."
                              />
                            ) : (
                              ""
                            )}
                            <p
                              style={{
                                position: "absolute",
                                top: "65%",
                                left: "50%",
                                width: "65px",
                                height: "65px",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              {/* <img src={logo} alt="..." />  */}
                              {prod.design1.image1 !==
                              imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : prod.design2.image2 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : prod.design3.image3 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : prod.design4.image4 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : prod.design5.image5 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : prod.design6.image6 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="..." />
                              ) : (
                                ""
                              )}
                            </p>
                          </CardHeader>
                          <CardBody>
                            <h6
                              className={classNames(
                                classes.cardCategory,
                                classes.textRose
                              )}
                            >
                              {prod.plating.description.slice(8) +
                                " " +
                                prod.cat.description}{" "}
                              <br /> {prod.banglessizemm.description}{" "}
                              {prod.itemcode}-{prod.id}
                            </h6>
                            <h4 className={classes.cardTitle}>
                              {prod.description}
                            </h4>
                            <div className={classes.cardDescription}>
                              {(
                                prod.metalOption.readygram * prod.sets.pcs
                              ).toFixed(4)}{" "}
                              Avg Gram Of {prod.sets.setdesc} {prod.box.boxdesc}{" "}
                              {prod.ctndescription}
                            </div>
                          </CardBody>
                          <CardFooter className={classes.justifyContentBetween}>
                            <div className={classes.price}>
                              {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5><span style={{ color: "red" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>} */}

                              {!activeSession ? (
                                prod.taxCalculation.profitactive === 1 ? (
                                  prod.taxCalculation.cash_gst === 1 ? (
                                    <>
                                      <h5>
                                        <span style={{ color: "red" }}>
                                          Price ₹{" "}
                                          {(
                                            prod.taxCalculation.taxincl /
                                            prod.box.box
                                          ).toFixed(2)}
                                          /-
                                        </span>
                                      </h5>{" "}
                                      <h6>
                                        <a href="/bangles-login">Login</a> to
                                        see prices and exciting offers.
                                      </h6>{" "}
                                    </>
                                  ) : (
                                    <h5>
                                      Price{" "}
                                      <span>
                                        ₹{" "}
                                        {(
                                          prod.taxCalculation.cash /
                                          prod.box.box
                                        ).toFixed(2)}
                                        /-*
                                      </span>
                                    </h5>
                                  )
                                ) : prod.taxCalculation.cash_gst === 1 ? (
                                  <>
                                    <h5>
                                      <span style={{ color: "#9c27b0" }}>
                                        Price ₹{" "}
                                        {(
                                          prod.taxCalculation.taxincl /
                                          prod.box.box
                                        ).toFixed(2)}
                                        /-
                                      </span>
                                    </h5>{" "}
                                    <h6>
                                      <a href="/bangles-login">Login</a> to see
                                      prices and exciting offers.
                                    </h6>
                                  </>
                                ) : (
                                  <h5>
                                    Price{" "}
                                    <span>
                                      ₹{" "}
                                      {(
                                        prod.taxCalculation.cash / prod.box.box
                                      ).toFixed(2)}
                                      /-*
                                    </span>
                                  </h5>
                                )
                              ) : (
                                ""
                              )}

                              {activeSession ? (
                                prod.taxCalculation.profitactive === 1 ? (
                                  <h6>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        color: "red",
                                      }}
                                    >
                                      Price ₹
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /
                                    </span>{" "}
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </h6>
                                ) : (
                                  <h6>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        color: "#9c27b0",
                                      }}
                                    >
                                      Price ₹
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /
                                    </span>{" "}
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </h6>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div className={classes.stats}>
                              <Tooltip
                                id="tooltip-top"
                                title="Save to Wishlist"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button justIcon color="rose" simple>
                                  <Favorite />
                                </Button>
                              </Tooltip>
                            </div>
                          </CardFooter>
                          {/* <CardFooter className={classes.justifyContentBetween}>
                                            <div className={classes.price}>
                                                <Button color="info" href={`/bangles-product?id=${prod.id}&catdesc=${prod.cat.description}&cat1desc=${prod.banglessizemm.description}&Plating=${prod.plating.description.slice(8)}`}><Subject />Read More</Button>
                                    
                                            </div>
                                            <div>
                                                <Button color="success" round justIcon href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp}&text=https://bangles.org.in/bangles-product?id=${prod.id}%20${prod.banglessizemm.description}%20${prod.plating.description.slice(8)}&app_absent=0`}>
                                                    <i className="fab fa-whatsapp" style={{ fontSize: '30px' }} />
                                                </Button>
                                            </div>
                                        </CardFooter> */}
                        </Card>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </InfiniteScroll>
          </div>
          <GridItem
            md={3}
            sm={3}
            className={classNames(classes.mlAuto, classes.mrAuto)}
          >
            <Button color="info" round>
              Loading...
            </Button>
          </GridItem>
        </div>
      </div>
      <BanglesFooter />
    </div>
  );
}
