import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Mail from "@material-ui/icons/Mail";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SearchIcon from "@material-ui/icons/Search";
import Header from "views/Main/MainHeader.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import bg7 from "assets/img/bg7.jpg";

const useStyle = makeStyles(styles);
const useStyles = makeStyles(headersStyle);

export default function app({ ...rest }) {
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          absolute
          // fixed
          brand="Home"
          color="transparent"
          links={
            <div className={classes.collapse}>
              <List className={classes.list + " " + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.navLink}
                    onClick={(e) => e.preventDefault()}
                    color="transparent"
                  >
                    Help
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.navLink}
                    onClick={(e) => e.preventDefault()}
                    color="transparent"
                  >
                    Message
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.navLink}
                    onClick={(e) => e.preventDefault()}
                    color="transparent"
                  >
                    Sign In
                  </Button>
                </ListItem>
              </List>
            </div>
          }
        />
        <div
          className={classNames(
            classe.subscribeLine,
            classe.subscribeLineImage
          )}
          style={{ backgroundImage: `url(${bg7})` }}
        >
          <div className={classe.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={classNames(classe.mlAuto, classe.mrAuto)}
              >
                <div className={classe.textCenter} style={{ marginTop: "20%" }}>
                  <h3 className={classe.title}>Subscribe to our Newsletter</h3>
                  <p className={classe.description}>
                    Join our newsletter and get news in your inbox every week!
                    We hate spam too, so no worries about this.
                  </p>
                </div>
                <Card raised className={classe.card}>
                  <CardBody className={classe.cardBody}>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6} lg={8}>
                          <CustomInput
                            id="emailPreFooter"
                            formControlProps={{
                              fullWidth: true,
                              className: classe.cardForm,
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                              placeholder: "Enter Product Name...",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <Button
                            color="primary"
                            block
                            className={classe.subscribeButton}
                          >
                            search
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
