/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getStates, getCities } from "utils/util";
import { MdLocalShipping } from "react-icons/md";
import { RiMapPinUserFill } from "react-icons/ri";
import SweetAlert from "react-bootstrap-sweetalert";

import BanglesHeader from "views/BanglesPage/Section/BanglesHeader";
import HeaderLink from "views/BanglesPage/Section/BanglesHeaderLink";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import basicsCheck from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import { localApi } from "utils/headApi";

import { Redirect } from "react-router-dom";

const useStyles = makeStyles(profilePageStyle);
const useStyle = makeStyles(basicsCheck);
const useStyleAlert = makeStyles(style);
const useStyl = makeStyles(aboutUsStyle);

export default function UpdateAddress({ ...rest }) {
  const [user, setUser] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [whatsApp, setWhatApp] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstIn, setGstIn] = React.useState("");
  const [gstInErr, setGstInErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [aliasName, setAliasName] = React.useState("");
  const [plotHouseNo, setPlotHouseNo] = React.useState("");
  const [areaColony, setAreaColony] = React.useState("");
  const [landMark, setLandMark] = React.useState("");
  const [road, setRoad] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeErr, setZipCodeErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [active, setActive] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState("in");
  const [stateSelect, setStateSelect] = React.useState("");
  const [stateSelectErr, setStateSelectErr] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [citySelectErr, setCitySelectErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");

  const [clientid, setClientid] = React.useState("");
  const [registerid, setRegisterid] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");

  const [addId, setAddId] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");

  // React.useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  // });
  const classes = useStyles();
  const classDrop = useStyle();
  const classeAlert = useStyleAlert();
  const classeTitel = useStyl();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  // const headApi = `https://api.bangles.org.in/api/rest`;

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateSelectErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCitySelectErr("");
  };

  const handleSimpleType = (event) => {
    const { value } = event.target;
    setSimpleSelectType(value);
    setSimpleSelectTypeErr("");
    // console.log(event.target.value);

    axios
      .get(
        localApi +
          `/regAddress/list/byAdminAndRegisterId/${userObj.admin.id}/${userObj.id}`
      )
      .then((res) => {
        const rg = res.data.filter((r) => r.addressType === value)[0];
        if (rg === undefined) {
          // console.log(rg);
          setSimpleSelectTypeErr(`Data Not Found in ${value}`);
        } else {
          setAddId(rg.id);
          setFirstName(rg.firstName);
          setLastName(rg.lastName);
          setMobile(rg.mobile);
          setWhatApp(rg.whatsApp);
          setAadharNumber(rg.aadharNumber);
          setPanNumber(rg.panNumber);
          setGstIn(rg.gstIn);
          setCompany(rg.company);
          setAliasName(rg.aliasName);
          setPlotHouseNo(rg.plotHouseNo);
          setAreaColony(rg.areaColony);
          setLandMark(rg.landMark);
          setRoad(rg.road);
          setZipCode(rg.zipCode);
          // setStateSelect(rg.state);
          setState(rg.state);
          // setCitySelect(rg.city);
          setCity(rg.city);
        }
      });
  };

  const handleGST = (e) => {
    const { value } = e.target;
    setGstIn(value);
    setGstInErr("");

    fetch(
      `https://sheet.gstincheck.co.in/check/028ec5a297d1ae03ba935ac49586e228/${value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "GSTIN  found.") {
          setAreaColony(res.data.pradr.adr);
          setStateSelect(res.data.pradr.addr.stcd);
          setCitySelect(res.data.pradr.addr.dst);
        } else if (res.message === "Invalid GSTIN Number") {
          setGstInErr("Invalid GSTIN Number");
        } else if (res.message === "Credit Not Available") {
          setGstInErr("Not Available");
        }
        // console.log(res);
      });
  };

  const Submit = () => {
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Mobile Number");
    }
    if (zipCode === "" || !zipCode) {
      errorCount++;
      setZipCodeErr("Please Enter Zip Code");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCitySelectErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateSelectErr("Please Select State Name");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Address Type");
    }
    // let regTest =
    //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
    let regTest = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regTest.test(gstIn)) {
      errorCount++;
      setGstInErr("GST No. is valid");
    }

    let gstVal = gstIn;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/;
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[A-Z]{5}$/;
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/;
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[A-Z]{1}$/;
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9A-Z]{1}$/;
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^Z$/;
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9A-Z]{1}$/;
    if (gstVal.length != 15) {
      setGstInErr(
        "Length should be restricted to 15 digits and should not allow anything more or less"
      );
    } else if (!patternstatecode.test(statecode)) {
      setGstInErr("First two characters of GSTIN should be Number");
    } else if (!patternthreetoseven.test(threetoseven)) {
      setGstInErr("Third to seventh characters of GSTIN should be Alphabets Cappital Letter");
    } else if (!patternseventoten.test(seventoten)) {
      setGstInErr("Eighth to Eleventh characters of GSTIN should be Number");
    } else if (!patternTwelveth.test(Twelveth)) {
      setGstInErr("Twelveth character of GSTIN should be Alphabet capital Letter");
    } else if (!patternThirteen.test(Thirteen)) {
      setGstInErr(
        "Thirteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (!patternfourteen.test(fourteen)) {
      setGstInErr("fourteen characters of GSTIN should be Alphabet Capital Letter");
    } else if (!patternfifteen.test(fifteen)) {
      setGstInErr(
        "fifteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (statecode < 1 || statecode > 37) {
      setGstInErr("Invalid First Two Characters of GSTIN");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          registerId: userObj.id,
          addressType: simpleSelectType,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          whatsApp: whatsApp,
          aadharNumber: aadharNumber,
          panNumber: panNumber,
          gstIn: gstIn,
          company: company,
          aliasName: aliasName,
          plotHouseNo: plotHouseNo,
          areaColony: areaColony,
          landMark: landMark,
          road: road,
          zipCode: zipCode,
          city: citySelect,
          state: stateSelect,
          country: country,
        }),
      };

      fetch(
        localApi + `/regAddress/update/byRegAddressId/${addId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Updated Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  useEffect(() => {
    // console.log("Value Changed")
    setAddId("");
    setFirstName("");
    setLastName("");
    setMobile("");
    setWhatApp("");
    setAadharNumber("");
    setPanNumber("");
    setGstIn("");
    setCompany("");
    setAliasName("");
    setPlotHouseNo("");
    setAreaColony("");
    setLandMark("");
    setRoad("");
    setZipCode("");
    // setStateSelect(rg.state);
    setState("");
    // setCitySelect(rg.city);
    setCity("");
  }, [simpleSelectType]);

  return (
    <div>
      {activeSession ? (
        <>
          <Helmet>
            <title>UpdateAddress</title>
          </Helmet>
          <BanglesHeader
            color="info"
            brand="Home"
            links={<HeaderLink dropdownHoverColor="info" />}
            fixed
            // changeColorOnScroll={{
            //     height: 200,
            //     color: "info",
            // }}
            {...rest}
          />
          <Parallax
            image={require("assets/img/examples/city.jpg").default}
            filter="dark"
            className={classes.parallax}
          >
            <div className={classeTitel.container}>
              <GridContainer justify="center">
                <GridItem
                  md={8}
                  sm={8}
                  className={classNames(
                    classeTitel.mlAuto,
                    classeTitel.mrAuto,
                    classeTitel.textCenter
                  )}
                >
                  <h1 className={classeTitel.title}>UPDATE ADDRESS</h1>
                  <h4></h4>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                />
              ) : null}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <h3 className={classes.title}>
                      <RiMapPinUserFill color="#00acc1" size={35} />
                      Update Billing Address
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Address Type...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelectType}
                      onChange={handleSimpleType}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Address Type
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="AADHAR ADDRESS"
                      >
                        AADHAR ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="GSTIN ADDRESS"
                      >
                        GSTIN ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="DELIVERY ADDRESS"
                      >
                        DELIVERY ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="OTHER ADDRESS"
                      >
                        OTHER ADDRESS
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{simpleSelectTypeErr}</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    inputProps={{
                      placeholder: "First Name ...",
                      name: "firstName",
                      value: firstName,
                      onChange: (e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {firstNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    inputProps={{
                      placeholder: "Last Name ...",
                      name: "lastName",
                      value: lastName,
                      onChange: (e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {lastNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>Mobile Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="mobile"
                    value={mobile}
                    onChange={(mobile) => {
                      let country_code =
                        document.body.querySelector("div.flag")?.className;
                      country_code =
                        country_code && country_code.replace("flag", "");
                      setCountryCode(country_code.trim());
                      // console.log(country_code);
                      handleMobileNumber(mobile);
                      let country_name =
                        document.body.querySelector("div.selected-flag")?.title;
                      setCountry(country_name.slice(0, -6));
                    }}
                    // disabled={true}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {mobileErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>WhatsApp Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="whatsApp"
                    value={whatsApp}
                    onChange={(whatsApp) => {
                      setWhatApp(whatsApp);
                    }}
                    // required={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Aadhar Number"
                    id="aadharNumber"
                    inputProps={{
                      placeholder: "Aadhar Number ...",
                      name: "aadharNumber",
                      value: aadharNumber,
                      onChange: (e) => setAadharNumber(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Pan Number"
                    id="panNumber"
                    inputProps={{
                      placeholder: "Pan Number ...",
                      name: "panNumber",
                      value: panNumber,
                      onChange: (e) => setPanNumber(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="GSTIN Number"
                    id="gstIn"
                    inputProps={{
                      placeholder: "GSTIN Number ...",
                      name: "gstIn",
                      value: gstIn,
                      onChange: handleGST,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // onChange={(e) => setGstIn(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{gstInErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Company"
                    id="company"
                    inputProps={{
                      placeholder: "Company ...",
                      name: "company",
                      value: company,
                      onChange: (e) => setCompany(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Alias Name"
                    id="aliasName"
                    inputProps={{
                      placeholder: "Alias Name ...",
                      name: "aliasName",
                      value: aliasName,
                      onChange: (e) => setAliasName(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Plot House No."
                    id="plotHouseNo"
                    inputProps={{
                      placeholder: "Plot, House No., Building ...",
                      name: "plotHouseNo",
                      value: plotHouseNo,
                      onChange: (e) => setPlotHouseNo(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Area Colony"
                    id="areaColony"
                    inputProps={{
                      placeholder: "Area Colony Street Vilage ...",
                      name: "areaColony",
                      value: areaColony,
                      onChange: (e) => {
                        setAreaColony(e.target.value);
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Land Mark"
                    id="landMark"
                    inputProps={{
                      placeholder: "LandMark e.g. Near In-Orbit Mall ...",
                      name: "landMark",
                      value: landMark,
                      onChange: (e) => setLandMark(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Road"
                    id="road"
                    inputProps={{
                      placeholder: "Road ...",
                      name: "road",
                      value: road,
                      onChange: (e) => setRoad(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Zip Code"
                    id="zipCode"
                    inputProps={{
                      placeholder: "Zip Code ...",
                      name: "zipCode",
                      value: zipCode,
                      onChange: (e) => {
                        setZipCode(e.target.value);
                        setZipCodeErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {zipCodeErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select State...{state}
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={stateSelect}
                      onChange={handleState}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select State
                      </MenuItem>
                      {getStates(countryCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => {
                            setStateCode(item?.state_code);
                          }}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{stateSelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select City...{city}
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={citySelect}
                      onChange={handleCity}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select City
                      </MenuItem>
                      {getCities(countryCode, stateCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{citySelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    inputProps={{
                      placeholder: "Country ...",
                      name: "country",
                      value: country,
                      onChange: (e) => setCountry(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // disabled={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="center">
                <Button color="info" onClick={Submit}>
                  Update
                </Button>
              </GridContainer>
              <br />
            </div>
          </div>
          <BanglesFooter />
        </>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
}
