/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import Badge from "components/Badge/Badge";
import classNames from "classnames";
import { getStates, getCities } from "utils/util";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { MdLocalShipping } from "react-icons/md";
import { RiMapPinUserFill } from "react-icons/ri";
import Slide from "@material-ui/core/Slide";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import { FiPhoneCall } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SweetAlert from "react-bootstrap-sweetalert";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import basicsCheck from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

import BanglesHeader from "views/BanglesPage/Section/BanglesHeader";
import BanglesHeaderLink from "views/BanglesPage/Section/BanglesHeaderLink";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter";

const useStyles = makeStyles(profilePageStyle);
const useStyle = makeStyles(basicsCheck);
const useStylPop = makeStyles(popOver);
const useStyleAlert = makeStyles(style);
const useStyleTool = makeStyles(productStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CartShippingAddress({ ...rest }) {
  const [newAddress, setNewAddress] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [updateAdd, setUpdateAdd] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [whatsApp, setWhatApp] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [aadharNumberErr, setAadharNumberErr] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstIn, setGstIn] = React.useState("");
  const [gstInErr, setGstInErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [aliasName, setAliasName] = React.useState("");
  const [plotHouseNo, setPlotHouseNo] = React.useState("");
  const [areaColony, setAreaColony] = React.useState("");
  const [landMark, setLandMark] = React.useState("");
  const [road, setRoad] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeErr, setZipCodeErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [active, setActive] = React.useState(false);
  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [submittedExits, setSubmittedExits] = React.useState(false);
  const [regClient, setRegClient] = React.useState("");

  const [countryCode, setCountryCode] = React.useState("in");
  const [stateSelect, setStateSelect] = React.useState("");
  const [stateSelectErr, setStateSelectErr] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [citySelectErr, setCitySelectErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");

  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");

  // React.useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  // });
  const classes = useStyles();
  const classDrop = useStyle();
  const classPop = useStylPop();
  const history = useHistory();
  const classeAlert = useStyleAlert();
  const classeHover = useStyleTool();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        setRegClient(json.ClientList);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  React.useEffect(() => {
    axios
      .get(
        localApi +
          `/regAddress/list/byAdminAndRegisterId/${userObj.admin.id}/${userObj.id}`
      )
      .then((res) => {
        // console.log(res.data);
        setUpdateAdd(res && res.data);
      });
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateSelectErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCitySelectErr("");
  };

  const handleSimpleType = (event) => {
    const { value } = event.target;
    setSimpleSelectType(value);
    setSimpleSelectTypeErr("");
    // console.log(event.target.value);

    const rClient = regClient.find((f) => f.regId === userObj.id);

    fetch(
      localApi +
        `/regAddress/findByAddressTypeAndAdminAndClientAndRegisterId/${userObj.admin.id}/${rClient.id}/${userObj.id}/${value}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setSimpleSelectTypeErr(result.message);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleGST = (e) => {
    const { value } = e.target;
    setGstIn(value);
    setGstInErr("");

    fetch(
      `https://sheet.gstincheck.co.in/check/028ec5a297d1ae03ba935ac49586e228/${value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "GSTIN  found.") {
          setAreaColony(res.data.pradr.adr);
          setStateSelect(res.data.pradr.addr.stcd);
          setCitySelect(res.data.pradr.addr.dst);
        } else if (res.message === "Invalid GSTIN Number") {
          setGstInErr("Invalid GSTIN Number");
        } else if (res.message === "Credit Not Available") {
          setGstInErr("Not Available");
        }
        // console.log(res);
      });
  };

  const Submit = () => {
    const rClient = regClient.find((f) => f.regId === userObj.id);
    // console.log(rClient);

    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Mobile Number");
    }
    if (zipCode === "" || !zipCode) {
      errorCount++;
      setZipCodeErr("Please Enter Zip Code");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCitySelectErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateSelectErr("Please Select State Name");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Address Type");
    }
    if (aadharNumber === "" || !aadharNumber) {
      errorCount++;
      setAadharNumberErr("Please Enter Aadhar Number");
    }
    // let regTest =
    //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
    let regTest = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regTest.test(gstIn)) {
      errorCount++;
      setGstInErr("GST No. is valid");
    }

    let gstVal = gstIn;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/;
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[A-Z]{5}$/;
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/;
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[A-Z]{1}$/;
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9A-Z]{1}$/;
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^Z$/;
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9A-Z]{1}$/;
    if (gstVal.length != 15) {
      setGstInErr(
        "Length should be restricted to 15 digits and should not allow anything more or less"
      );
    } else if (!patternstatecode.test(statecode)) {
      setGstInErr("First two characters of GSTIN should be Number");
    } else if (!patternthreetoseven.test(threetoseven)) {
      setGstInErr("Third to seventh characters of GSTIN should be Alphabets Cappital Letter");
    } else if (!patternseventoten.test(seventoten)) {
      setGstInErr("Eighth to Eleventh characters of GSTIN should be Number");
    } else if (!patternTwelveth.test(Twelveth)) {
      setGstInErr("Twelveth character of GSTIN should be Alphabet capital Letter");
    } else if (!patternThirteen.test(Thirteen)) {
      setGstInErr(
        "Thirteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (!patternfourteen.test(fourteen)) {
      setGstInErr("fourteen characters of GSTIN should be Alphabet Capital Letter");
    } else if (!patternfifteen.test(fifteen)) {
      setGstInErr(
        "fifteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (statecode < 1 || statecode > 37) {
      setGstInErr("Invalid First Two Characters of GSTIN");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.admin.id,
          registerId: userObj.id,
          clientId: rClient.id,
          addressType: simpleSelectType,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          whatsApp: whatsApp,
          aadharNumber: aadharNumber,
          panNumber: panNumber,
          gstIn: gstIn,
          company: company,
          aliasName: aliasName,
          plotHouseNo: plotHouseNo,
          areaColony: areaColony,
          landMark: landMark,
          road: road,
          zipCode: zipCode,
          city: citySelect,
          state: stateSelect,
          country: country,
        }),
      };

      fetch(localApi + "/regAddress/add", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Already Exist") {
            alertTypeOps("danger");
            alertMsgOps("Already Exits !!!");
            setSubmittedExits(true);
          } else if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Added Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    // console.log(cartId);
    fetch(localApi + `/regAddress/delete/byId/${cartId.id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const handleActive = (a) => {
    if (checkedFullSize === false) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          country: "India",
        }),
      };

      fetch(
        localApi +
          `/regAddress/update/byAdminId/${userObj.admin.id}/${userObj.id}/${a.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Successfully");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      <Helmet>
        <title>Cart Shipping Address</title>
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<BanglesHeaderLink dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/ban.webp").default}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {submittedExits ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={
                classeAlert.button + " " + classeAlert.success
              }
            />
          ) : null}
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={
                classeAlert.button + " " + classeAlert.success
              }
            >
              Your delivery address is updated!!
            </SweetAlert>
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={
                classeAlert.button + " " + classeAlert.success
              }
              cancelBtnCssClass={classeAlert.button + " " + classeAlert.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={
                classeAlert.button + " " + classeAlert.success
              }
            >
              Your item has been deleted.
            </SweetAlert>
          ) : null}
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <h3 className={classes.title}>
                  <MdLocalShipping color="#00acc1" size={35} /> Shipping Address
                </h3>
              </div>
            </GridItem>
          </GridContainer>
          {newAddress === true ? (
            <GridContainer>
              <GridItem md={12} sm={12} xs={12}>
                <Button color="info" onClick={() => setNewAddress(false)}>
                  Add New Address
                </Button>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem md={12} sm={12} xs={12}>
                <Button color="info" onClick={() => setNewAddress(true)}>
                  Add New Address
                </Button>
              </GridItem>
            </GridContainer>
          )}
          <br />
          {newAddress === true ? (
            <>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Address Type...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelectType}
                      onChange={handleSimpleType}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Address Type
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="AADHAR ADDRESS"
                      >
                        AADHAR ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="GSTIN ADDRESS"
                      >
                        GSTIN ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="DELIVERY ADDRESS"
                      >
                        DELIVERY ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="OTHER ADDRESS"
                      >
                        OTHER ADDRESS
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{simpleSelectTypeErr}</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    inputProps={{
                      placeholder: "First Name ...",
                      name: "firstName",
                      value: firstName,
                      onChange: (e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {firstNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    inputProps={{
                      placeholder: "Last Name ...",
                      name: "lastName",
                      value: lastName,
                      onChange: (e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {lastNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>Mobile Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="mobile"
                    value={mobile}
                    onChange={(mobile) => {
                      let country_code =
                        document.body.querySelector("div.flag")?.className;
                      country_code =
                        country_code && country_code.replace("flag", "");
                      setCountryCode(country_code.trim());
                      // console.log(country_code);
                      handleMobileNumber(mobile);
                      let country_name =
                        document.body.querySelector("div.selected-flag")?.title;
                      setCountry(country_name.slice(0, -6));
                    }}
                    // disabled={true}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {mobileErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>WhatsApp Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="whatsApp"
                    value={whatsApp}
                    onChange={(whatsApp) => {
                      setWhatApp(whatsApp);
                    }}
                    // required={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Aadhar Number"
                    id="aadharNumber"
                    inputProps={{
                      placeholder: "Aadhar Number ...",
                      name: "aadharNumber",
                      value: aadharNumber,
                      onChange: (e) => {
                        setAadharNumber(e.target.value);
                        setAadharNumberErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {aadharNumberErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Pan Number"
                    id="panNumber"
                    inputProps={{
                      placeholder: "Pan Number ...",
                      name: "panNumber",
                      value: panNumber,
                      onChange: (e) => setPanNumber(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="GSTIN Number"
                    id="gstIn"
                    inputProps={{
                      placeholder: "GSTIN Number ...",
                      name: "gstIn",
                      value: gstIn,
                      onChange: handleGST,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // onChange={(e) => setGstIn(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{gstInErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Company"
                    id="company"
                    inputProps={{
                      placeholder: "Company ...",
                      name: "company",
                      value: company,
                      onChange: (e) => setCompany(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Alias Name"
                    id="aliasName"
                    inputProps={{
                      placeholder: "Alias Name ...",
                      name: "aliasName",
                      value: aliasName,
                      onChange: (e) => setAliasName(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Plot House No."
                    id="plotHouseNo"
                    inputProps={{
                      placeholder: "Plot, House No., Building ...",
                      name: "plotHouseNo",
                      value: plotHouseNo,
                      onChange: (e) => setPlotHouseNo(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Area Colony"
                    id="areaColony"
                    inputProps={{
                      placeholder: "Area Colony Street Vilage ...",
                      name: "areaColony",
                      value: areaColony,
                      onChange: (e) => {
                        setAreaColony(e.target.value);
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Land Mark"
                    id="landMark"
                    inputProps={{
                      placeholder: "LandMark e.g. Near In-Orbit Mall ...",
                      name: "landMark",
                      value: landMark,
                      onChange: (e) => setLandMark(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Road"
                    id="road"
                    inputProps={{
                      placeholder: "Road ...",
                      name: "road",
                      value: road,
                      onChange: (e) => setRoad(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Zip Code"
                    id="zipCode"
                    inputProps={{
                      placeholder: "Zip Code ...",
                      name: "zipCode",
                      value: zipCode,
                      onChange: (e) => {
                        setZipCode(e.target.value);
                        setZipCodeErr("");
                      },
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {zipCodeErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select State
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={stateSelect}
                      onChange={handleState}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select State
                      </MenuItem>
                      {getStates(countryCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => {
                            setStateCode(item?.state_code);
                          }}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{stateSelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select City
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={citySelect}
                      onChange={handleCity}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select City
                      </MenuItem>
                      {getCities(countryCode, stateCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{citySelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    inputProps={{
                      placeholder: "Country ...",
                      name: "country",
                      value: country,
                      onChange: (e) => setCountry(e.target.value),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // disabled={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="center">
                <Button color="info" onClick={Submit}>
                  Save
                </Button>
              </GridContainer>
              <br />
            </>
          ) : (
            ""
          )}
          <br />
        </div>
      </div>

      <div
        className={classNames(classes.mainRaised)}
        style={{ marginTop: "2%" }}
      >
        <GridContainer>
          {updateAdd &&
            updateAdd.map((a) => (
              <GridItem xs={12} sm={12} md={3} key={a.id}>
                <Card>
                  <CardBody>
                    <p className={classes.cardDescription}>
                      {a && a.company
                        ? a.company
                        : a.firstName + " " + a.lastName}
                      <small style={{ float: "right", marginRight: "5px" }}>
                        <Tooltip
                          id="tooltip-top"
                          title="Deliver to this address"
                          placement="left"
                          classes={{ tooltip: classeHover.tooltip }}
                        >
                          <Switch
                            onChange={(e) => {
                              setCheckedFullSize(e.target.checked);
                              handleActive(a);
                            }}
                            key={a.id}
                            value={a.id}
                            checked={
                              checkedFullSize && checkedFullSize
                                ? checkedFullSize === a.id
                                : a.active === true
                            }
                            id={a.id}
                            name="checkedFullSize"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </Tooltip>
                      </small>
                    </p>
                    <p className={classes.cardDescription}>
                      {a.active === true ? (
                        <Badge color="success">{a.addressType}</Badge>
                      ) : (
                        <Badge>{a.addressType}</Badge>
                      )}
                    </p>
                    <p className={classes.cardDescription}>
                      {a.plotHouseNo + " " + a.areaColony}
                      <br />
                      {a.landMark + " " + a.road + " - " + a.zipCode}
                      <br />
                      {a.city + ", " + a.state + ", " + a.country}
                    </p>
                    <div
                      className={
                        classes.stats + " " + classes.justifyContentCenter
                      }
                    >
                      {a.active === true ? (
                        <Button
                          color="info"
                          href={
                            a.active === true
                              ? `/cart-view?client=${a.clientId}&address=${a.id}&transport=1&reg=${userObj.id}`
                              : ""
                          }
                        >
                          Proceed
                        </Button>
                      ) : (
                        <Button
                          color="default"
                          href={
                            a.active === true
                              ? `/cart-view?client=${a.clientId}&address=${a.id}&transport=1&reg=${userObj.id}`
                              : ""
                          }
                        >
                          Proceed
                        </Button>
                      )}
                      <Tooltip
                        id="tooltip-top"
                        title="Remove"
                        placement="right"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Button
                          color="danger"
                          simple
                          justIcon
                          onClick={() => handleRemove(a)}
                          size="lg"
                          style={{
                            marginTop: "0px",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <DeleteForeverIcon
                            className={classeHover.underChartIcons}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ))}

          {/* {updateAdd &&
            updateAdd.map((a) => (
              <GridItem xs={12} sm={12} md={4} key={a.id}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <div style={{ float: "left", marginLeft: "0px" }}>
                          <Tooltip
                            id="tooltip-top"
                            title="Remove"
                            placement="right"
                            classes={{ tooltip: classeHover.tooltip }}
                          >
                            <Button
                              color="danger"
                              simple
                              justIcon
                              onClick={() => handleRemove(a)}
                              size="lg"
                              style={{ marginTop: "0px" }}
                            >
                              <DeleteForeverIcon
                                className={classeHover.underChartIcons}
                              />
                            </Button>
                          </Tooltip>
                          <br />
                          <h6
                            className={classes.cardIconTitle}
                            style={{ fontWeight: "bold", marginTop: "0px" }}
                          >
                            {"Delete Address"}
                          </h6>
                        </div>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6} key={a.id}>
                        <div
                          style={{ float: "right", marginRight: "20px" }}
                          key={a.id}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title="Deliver to this address"
                            placement="left"
                            classes={{ tooltip: classeHover.tooltip }}
                          >
                            <Switch
                              onChange={(e) => {
                                setCheckedFullSize(e.target.checked);
                                handleActive(a);
                              }}
                              key={a.id}
                              value={a.id}
                              checked={
                                checkedFullSize && checkedFullSize
                                  ? checkedFullSize === a.id
                                  : a.active === true
                              }
                              id={a.id}
                              name="checkedFullSize"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                          </Tooltip>
                          <br />
                          <label>
                            {a.active === true ? (
                              <h6
                                className={classes.cardIconTitle}
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "9px",
                                  marginTop: "25px",
                                  color: "red",
                                }}
                              >
                                <b>Deliver to this address</b>
                              </h6>
                            ) : (
                              <h6
                                className={classes.cardIconTitle}
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "9px",
                                  marginTop: "25px",
                                }}
                              >
                                <b>Deliver to this address</b>
                              </h6>
                            )}
                          </label>
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <h3 className={classes.companyAlign}>
                          <small>
                            {a && a.company
                              ? a.company
                              : a.firstName + " " + a.lastName}
                          </small>
                        </h3>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "0px" }}
                        >
                          <span
                            className={classes.cardIconTitle}
                            style={{ fontWeight: "bold", fontSize: "11px" }}
                          >
                            {a.addressType}
                          </span>
                          <br />
                          <small>
                            {a.plotHouseNo + " " + a.areaColony}
                            <br />
                            {a.landMark + " " + a.road + " - " + a.zipCode}
                            <br />
                            {a.city + ", " + a.state + ", " + a.country}
                          </small>
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <Button
                      color="info"
                      href={
                        a.active === true
                          ? `/cart-view?client=${a.clientId}&address=${a.id}&transport=5&reg=${userObj.id}`
                          : ""
                      }
                    >
                      Proceed
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            ))} */}
        </GridContainer>
      </div>
      <br />
      <BanglesFooter />
    </div>
  );
}
