import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhone } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";

import cartBillHeadStyle from "assets/jss/material-kit-pro-react/views/cartBillHeadStyle";

const useStyles = makeStyles(cartBillHeadStyle);

export default function BillHeader() {
  const [getItem, setGetItem] = React.useState([]);
  const [adminDetails, setAdminDetails] = React.useState("");
  const classes = useStyles();

  const search = useLocation().search;
  const byadminid = new URLSearchParams(search).get("byadminid");

  async function getProd() {
    const getAdmin = localApi + `/admin/byMobile/${byadminid}`;
    const adminResult = await fetch(getAdmin);
    const resAdmin = await adminResult.json();
    setAdminDetails(resAdmin);

    const get = localApi + `/adminAddress/list/byAdminId/${resAdmin.id}`;
    const results = await fetch(get);
    const res = await results.json();
    // console.log(res);
    setGetItem(res && res.filter((a) => a.addressType === "GSTIN ADDRESS" && a.active === true));
  }

  useEffect(() => {
    getProd();
  }, []);

  return (
    <div className={classes.productPage}>
      <GridContainer className={classes.borderLine}>
        <GridItem md={2} sm={2} xs={2}>
          <img
            src={adminDetails.logo != null ? localApi + `/admin/logoImageDownload/${adminDetails.logo}?id=${adminDetails.id}` : "apple-bangles-org-icon.png"}
            style={{ marginTop: "10px", height: "125px" }}
          />
        </GridItem>
        <GridItem
          md={8}
          sm={8}
          xs={8}
          className={
            classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
          }
        >
          <span className={classes.invoiceName}>PROFOMA INVOICE</span>
          <br />
          <span className={classes.companyName}>
            {getItem.map((a) => a.company)}
          </span>
          <br />
          <div className={classes.space}>
            <span className={classes.address}>
              {getItem.map((a) => a.plotHouseNo)}{" "}
              {getItem.map((a) => a.areaColony)}{" "}
              {getItem.map((a) => a.landMark)} {getItem.map((a) => a.road)}
              <br />
              {getItem.map((a) => a.city)} {"-"} {getItem.map((a) => a.zipCode)}
              , {getItem.map((a) => a.state)}, INDIA.
              <br />{" "}
              <strong className={classes.gst}>
                GSTIN : {getItem.map((a) => a.gstIn)}
              </strong>{" "}
            </span>
            <br />
            <span className={classes.mobileTel}>
              <ImMobile /> {getItem.map((a) => a.mobile)} <IoLogoWhatsapp />{" "}
              {getItem.map((a) => a.whatsApp)} <MdEmail /> {adminDetails.email}
            </span>
          </div>
        </GridItem>
        <GridItem md={2} sm={2} xs={2} className={classes.originalCopy}>
          <span>Original Copy</span>
        </GridItem>
      </GridContainer>
    </div>
  );
}
