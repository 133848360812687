import React from "react";
import classNames from "classnames";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
// import Header from "components/Header/Header.js";
import Header from "views/Main/MainHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import PinDrop from "@material-ui/icons/PinDrop";
import InfoArea from "components/InfoArea/InfoArea.js";
import Phone from "@material-ui/icons/Phone";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import Muted from "components/Typography/Muted.js";
import { SiWhatsapp } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import bg12 from "assets/img/bg12.jpg";
import axios from "axios";

const useStyles = makeStyles(headersStyle);
const useSty = makeStyles(featuresStyle);
const useStyl = makeStyles(projectsStyle);
const useStyle = makeStyles(contactsStyle);

export default function Igs({ ...rest }) {
    const [checked, setChecked] = React.useState([]);
    const [userDel, setUserDel] = React.useState([]);
    const [prodDel, setProdDel] = React.useState([]);
    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const classes = useStyles();
    const classAbout = useSty();
    const classProduct = useStyl();
    const classContact = useStyle();

    const activeSession = sessionStorage.getItem("sessionData");

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');
    
    axios.get(headApi + `/user/+${userid}`)
    .then((res) => setUserDel(res.data))

    // /products/limitList?userid=${userDel.id}&active=0&page=${page}&size=8

    axios.get(headApi + `/products/limitList?userid=1&active=0&page=1&size=8`)
    .then((res) => setProdDel(res.data.ProductsData))

    return (
        <div className="cd-section" {...rest}>
            {/* HEADER 1 START */}
            <div>
                <Header
                    absolute
                    // fixed
                    brand="Home"
                    color="transparent"
                    // color="info"
                    links={
                        <div className={classes.collapse}>
                            <List className={classes.list + " " + classes.mlAuto}>
                                <ListItem className={classes.listItem}>
                                    <Button
                                        href="#about"
                                        className={classes.navLink}
                                        // onClick={(e) => e.preventDefault()}
                                        color="transparent"
                                    >
                                        About us
                                    </Button>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <Button
                                        href="#product"
                                        className={classes.navLink}
                                        // onClick={(e) => e.preventDefault()}
                                        color="transparent"
                                    >
                                        Products
                                    </Button>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <Button
                                        href="#contact"
                                        className={classes.navLink}
                                        // onClick={(e) => e.preventDefault()}
                                        color="transparent"
                                    >
                                        Contact us
                                    </Button>
                                </ListItem>
                            </List>
                        </div>
                    }
                />
                <div
                    className={classes.pageHeader}
                    style={{ backgroundImage: `url("${bg12}")` }}
                    id="index"
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <h1 className={classes.title}>{userDel.company}</h1>
                                <h4>
                                    Bangles are more than accessories for Indian women. It is a tradition to wear {userDel.company} after marriage in various states of India like Maharashtra, West Bengal, Odisha, Rajasthan, and Gujarat. A Red {userDel.company} signifies energy and prosperity, while green Pola denotes good luck and fertility and White {userDel.company} symbolizes peace and happiness.
                                </h4>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>

            <Card>
                <CardBody>
                    <GridContainer id="about">
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classAbout.mlAuto + " " + classAbout.mrAuto + " " + classAbout.textCenter
                            }
                        >
                            <h2 className={classAbout.title}>Why our product is the best</h2>
                            <h5 className={classAbout.description}>
                                We are India{"'"}s one-stop shop for all your bangles needs, working especially on {userDel.company}. We are well known and popular in making Odisha {userDel.company}, Maharashtra {userDel.company}, Bengal {userDel.company}, Marwadi {userDel.company}, and Gujrati traditional {userDel.company}. We are one of the largest Manufacturer of Bangles for all festivals and occasions. We ensure that we create value for our customers in this ever-changing market. We have a no-compromise policy when it comes to quality. Our stringent adherence to the quality of the product as well as service (including after-sales service) has further ensured that {userDel.company} provides the best products to the customers.
                                We focus on offering tastefully and wide-range of designer bangles at reasonable rates with the lowest making charges. In a very short span of time, {userDel.company} has built a large family of loyal clients in India (especially in Kolkata, Odisha, Maharashtra, Gujarat and Rajasthan.
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <div className={classProduct.projects} id="product">
                        <div className={classProduct.container}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={
                                        classProduct.mlAuto + " " + classProduct.mrAuto + " " + classProduct.textCenter
                                    }
                                >
                                    <Muted>
                                        <h6>OUR WORK</h6>
                                    </Muted>
                                    <h2 className={classProduct.title}>
                                        Some of Our Awesome Products - 2
                                    </h2>
                                    <h5 className={classProduct.description}>
                                        Customise the order as per your requirements. we make bangles on {"'"}make to{"'"} order. You can get bangles of any size, design and colour
                                    </h5>
                                    <div className={classProduct.sectionSpace} />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                {prodDel && prodDel.map((prod) => (
                                <GridItem sm={6} md={3} key={prod.id}>
                                    <CardHeader image>
                                        {/* <a href="#pablo"> */}
                                            {/* <img src={cardProduct1} alt="cardProduct" /> */}
                                            {prod.design1.img1active === 1 ? <img src={imgApi + "" + prod.design1.image1} alt="..." /> :
                                                    prod.design2.img2active === 1 ? <img src={imgApi + "" + prod.design2.image2} alt="..." /> :
                                                        prod.design3.img3active === 1 ? <img src={imgApi + "" + prod.design3.image3} alt="..." /> :
                                                            prod.design4.img4active === 1 ? <img src={imgApi + "" + prod.design4.image4} alt="..." /> :
                                                                prod.design5.img5active === 1 ? <img src={imgApi + "" + prod.design5.image5} alt="..." /> :
                                                                    prod.design6.img6active === 1 ? <img src={imgApi + "" + prod.design6.image6} alt="..." /> : ""}
                                        {/* </a> */}
                                    </CardHeader>
                                    <CardBody>
                                        <h6
                                            className={classNames(
                                                classes.cardCategory,
                                                classes.textRose
                                            )}
                                        >
                                            {prod.cat.description + " " + prod.banglessizemm.description}
                                        </h6>
                                        <h4 className={classes.cardTitle}>{prod.description}</h4>
                                        <div className={classes.cardDescription}>
                                        {(prod.metalOption.readygram * prod.sets.pcs).toFixed(4)} Avg Gram Of {prod.sets.setdesc} {" "}
                                                {prod.box.boxdesc} {prod.ctndescription}
                                        </div>
                                    </CardBody>
                                    <CardFooter className={classes.justifyContentBetween} style={{marginBottom: "15%"}}>
                                        <div className={classes.price}>
                                        {!activeSession ? prod.taxCalculation.profitactive === 1 ? prod.taxCalculation.cash_gst === 1 ?
                                                    <><h5><span style={{ color: "red" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> </> :
                                                    <h5>Price <span>₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</span></h5> :
                                                    prod.taxCalculation.cash_gst === 1 ?
                                                        <><h5><span style={{ color: "#9c27b0" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> </> :
                                                        <h5>Price <span>₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</span></h5> : ""}

                                                {activeSession ? prod.taxCalculation.profitactive === 1 ? <h6><span style={{ textDecoration: "line-through", color: "red" }}>Price ₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6><span style={{ textDecoration: "line-through", color: "#9c27b0" }}>Price ₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> : ""}
                                        </div>
                                    </CardFooter>
                                </GridItem>
                                ))}
                            </GridContainer>
                        </div>
                    </div>

                </CardBody>
            </Card>
            {/* Project 2 END */}
            <GridContainer id="contact" justify="center" alignItems="center">
                <GridItem xs={12} sm={12} md={10}>
                    <Card
                    //  className={classContact.card2}
                    >
                        <form>
                            <CardHeader contact color="info" className={classContact.textCenter}>
                                <h4 className={classContact.cardTitle} style={{ color: "#FFF" }}>Contact Us</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <InfoArea
                                            className={classContact.infoArea2}
                                            title="Call us at"
                                            description={
                                                <span>
                                                    {userDel.mobile ? <ImMobile color="red" /> : ""} {userDel && userDel.mobile ? userDel.mobile : ""} <br />
                                                    {userDel.whatsapp ? <IoLogoWhatsapp color="green" /> : ""} {userDel && userDel.whatsapp ? userDel.whatsapp : ""}
                                                </span>
                                            }
                                            icon={Phone}
                                            iconColor="info"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <InfoArea
                                            className={classContact.infoArea2}
                                            title="Office Address"
                                            description={
                                                <span>
                                                    {userDel && userDel.address ? userDel.address : ""} {userDel && userDel.address1 ? userDel.address1 : ""}<br />
                                                    {userDel && userDel.address2 ? userDel.address2 : ""} {userDel && userDel.address3 ? userDel.address3 : ""} {userDel && userDel.zipcode ? userDel.zipcode : ""}<br />
                                                    {userDel && userDel.city ? userDel.city : ""} {userDel && userDel.state ? userDel.state : ""}<br />
                                                    {userDel && userDel.gstno ? "GSTIN / UIN" : "Aadhar No."} : {userDel && userDel.gstno ? userDel.gstno : userDel.aadharno}
                                                </span>
                                            }
                                            icon={PinDrop}
                                            iconColor="info"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <a
                                            href={`https://api.whatsapp.com/send?phone=${userDel.mobile}&text=https://bangles.org.in/%20Hello%20I%20am%20Interested%20in%20your%20Product&app_absent=0`}
                                        >
                                            <InfoArea
                                                className={classContact.infoArea2}
                                                title="Click on the Whatsapp icon to get connected with us"
                                                // description={
                                                //     <span>
                                                //         Bld Mihail Kogalniceanu, nr. 8,
                                                //         <br /> 7652 Bucharest,
                                                //         <br /> Romania
                                                //     </span>
                                                // }
                                                icon={SiWhatsapp}
                                                iconColor="success"
                                            />
                                        </a>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            labelText="Full Name"
                                            id="first2"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            labelText="Email Address"
                                            id="email-address2"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <CustomInput
                                    labelText="Your Message"
                                    id="message2"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 5,
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classContact.justifyContentBetween}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => handleToggle(2)}
                                            checkedIcon={<Check className={classContact.checkedIcon} />}
                                            icon={<Check className={classContact.uncheckedIcon} />}
                                            classes={{
                                                checked: classContact.checked,
                                                root: classContact.checkRoot,
                                            }}
                                        />
                                    }
                                    classes={{ label: classContact.label }}
                                    label="I'm not a robot"
                                />
                                <Button color="info" className={classContact.pullRight}>
                                    Send Message
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}