/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import Close from "@material-ui/icons/Close";
import { localApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import classNames from "classnames";
import ImageGallery from "react-image-gallery";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import CustomInput from "components/CustomInput/CustomInput.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalShipping from "@material-ui/icons/LocalShipping";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
import Popover from "@material-ui/core/Popover";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import NavPills from "components/NavPills/NavPills.js";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import { FiPhoneCall } from "react-icons/fi";
import SweetAlert from "react-bootstrap-sweetalert";
import CornerRibbon from "react-corner-ribbon";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import radio from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

import BanglesHeader from "./Section/BanglesHeader.js";
import HeaderLink from "./Section/BanglesHeaderLink.js";
import BanglesFooter from "./Section/BanglesFooter.js";
import logo from "assets/img/examples/apple-bangles-org-icon.png";

import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(radio);
const useStylPop = makeStyles(popOver);
const useStyleAlert = makeStyles(style);
const useSty = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function BanglesProduct() {
  // const headApi = `https://api.bangles.org.in/api/rest`;
  // const imgApi = `https://imgstore.bangles.org.in`;

  const [prodList, setProdList] = React.useState([]);
  const [item, setItem] = React.useState([]);
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [reSend, setReSend] = React.useState("");
  const [cat, setCat] = React.useState("");
  const [catMM, setCatMM] = React.useState("");
  const [plat, setPlat] = React.useState("");
  const [plating, setPlating] = React.useState("");
  const [metOption, setMetOption] = React.useState("");
  const [sets, setSets] = React.useState("");
  const [box, setBox] = React.useState("");
  const [packing, setPacking] = React.useState("");
  const [calculate, setCalculate] = React.useState("");
  const [labourList, setLobourList] = React.useState("");
  const [metal, setMetal] = React.useState("");
  const [selectedEnabled, setSelectedEnabled] = React.useState("a");
  const [anchorElTop, setAnchorElTop] = React.useState(null);
  const [size1x8, setSize1x8] = React.useState(0);
  const [size1x9, setSize1x9] = React.useState(0);
  const [size1x10, setSize1x10] = React.useState(0);
  const [size1x11, setSize1x11] = React.useState(0);
  const [size1x12, setSize1x12] = React.useState(0);
  const [size1x13, setSize1x13] = React.useState(0);
  const [size1x14, setSize1x14] = React.useState(0);
  const [size1x15, setSize1x15] = React.useState(0);
  const [size2x0, setSize2x0] = React.useState(0);
  const [size2x1, setSize2x1] = React.useState(0);
  const [size2x2, setSize2x2] = React.useState(0);
  const [size2x3, setSize2x3] = React.useState(0);
  const [size2x4, setSize2x4] = React.useState(0);
  const [size2x5, setSize2x5] = React.useState(0);
  const [size2x6, setSize2x6] = React.useState(0);
  const [size2x7, setSize2x7] = React.useState(0);
  const [size2x8, setSize2x8] = React.useState(0);
  const [size2x9, setSize2x9] = React.useState(0);
  const [size2x10, setSize2x10] = React.useState(0);
  const [size2x11, setSize2x11] = React.useState(0);
  const [size2x12, setSize2x12] = React.useState(0);
  const [size2x13, setSize2x13] = React.useState(0);
  const [size2x14, setSize2x14] = React.useState(0);
  const [size2x15, setSize2x15] = React.useState(0);
  const [size3x0, setSize3x0] = React.useState(0);
  const [size3x1, setSize3x1] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [loginModal, setLoginModal] = React.useState(false);
  const [prodMix, setProdMix] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [proTotal, setProTotal] = React.useState("");

  // const [reSend, setReSend] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [admin, setAdmin] = React.useState("");
  const [register, setRegister] = React.useState("");
  const [registerClient, setRegisterClient] = React.useState("");
  const [imageGal, setImageGal] = React.useState([]);

  const [checkedFullSize, setCheckedFullSize] = React.useState(true);
  const [checkedCutSize, setCheckedCutSize] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();
  const history = useHistory();
  const classeAlert = useStyleAlert();
  //  const classe = useStyl();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const search = useLocation().search;
  const catdesc = new URLSearchParams(search).get("catdesc");
  const cat1desc = new URLSearchParams(search).get("cat1desc");
  const id = new URLSearchParams(search).get("id");

  async function getProd() {
    const results = await fetch(
      localApi +
        `/product/list/byCatMM?admin_id=4&active=1&catdesc=${catdesc}&cat1desc=${cat1desc}`
    );
    const res = await results.json();
    // console.log(res.ProductsData);
    setProdList(res.filter((f) => f.id != id));
  }

  useEffect(() => {
    getProd();
  }, []);

  async function getAdmin() {
    const api = localApi + `/admin/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setAdmin(getResult.AdminList);
  }

  async function getRegister() {
    const api = localApi + `/register/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setRegister(getResult.ClientList);
  }

  async function getRegisterClient() {
    const api = localApi + `/clientGroup/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setRegisterClient(getResult.ClientList);
  }

  useEffect(() => {
    getAdmin();
    getRegister();
    getRegisterClient();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    // setMobileErr("");
    fetch(localApi + `/register/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Mobile No. Already Registered") {
            setMobileErr("");
          } else if (result.message === "Not Found") {
            setMobileErr("Your No. Not Registered");
          } else {
            setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result.message);
          if (result.message === "Email ID Already Registered") {
            setEmailErr("");
          } else if (result.message === "Not Found") {
            setEmailErr("Your Email Not Registered");
          } else {
            setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleOtp = (e) => {
    // console.log(e.target.value);
    setOtpNumber(e.target.value);
    setOtpNumberErr("");
  };

  const handleSendOtpWithMobile = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "arfaimpex.com");
    const api = localApi + `/register/byMobile/${mobile}`;
    const result = await fetch(api);
    const getResult = await result.json();
    fetch(
      localApi +
        `/otp/send/byMobile?mobile=${mobile}&templateID=1207161762762132153`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          websitename: "arfaimpex.com",
          followId: getFindAdmin.id,
          adminId: getResult.aId,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setMobileErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendOtpWithEmail = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "arfaimpex.com");
    const api = localApi + `/register/byEmail/${email}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: "API CREATION SUBJECT",
        websitename: "arfaimpex.com",
        followId: getFindAdmin.id,
        adminId: getResult.aId,
      }),
    };
    fetch(
      localApi + `/otp/send/byEmail?e=noreply@bangles.org.in`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setEmailErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginWithMobile = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
          `/register/recieved/byMobile?mobile=${mobile}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            setLoginModal(false);
            window.location.reload(false);
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginWithEmail = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/register/recieved/byEmail?email=${email}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            setLoginModal(false);
            window.location.reload(false);
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  async function productGet() {
    const get = localApi + `/product/findById/${id}`;
    const result = await fetch(get);
    const rest = await result.json();
    setImageGal(
      rest.productImage.map((c) => {
        return {
          original: c.cropImageUrl + "?id=" + c.imgId,
          thumbnail: c.cropImageUrl + "?id=" + c.imgId,
        };
      })
    );

    // const MixId = rest.mixmm ? rest.mixmm.split(",") : "";
    // const tempData = [];
    // MixId && MixId.forEach(x =>
    //   axios.get(headApi + `/products/byid/${x}`)
    //     .then(res => {
    //       // console.log(res.data);
    //       tempData.push(res.data);
    //     }))
    // setProdMix(tempData);

    setItem(rest);
    setCat(rest.productCategory);
    setCatMM(rest.productParent);
    // setPlat(rest.plating);
    // setPlating(rest.plating.description);
    // setMetOption(rest.metalOption);
    setSets(rest.productSets);
    setBox(rest.productBox);
    setPacking(rest.productPacking);
    // setCalculate(rest.taxCalculation);
    setLobourList(rest.productChild);
    setMetal(rest.productRawPrice);
    setProTotal(rest.stockSize.length);
    // console.log(rest.stockSize[0]);

    if (rest.stockSize[0] && rest.stockSize[0].cutOrFullSize == 3) {
      setCheckedFullSize(true);
      setCheckedCutSize(true);
    }
    if (rest.stockSize[0] && rest.stockSize[0].cutOrFullSize == 1) {
      setCheckedFullSize(true);
    }
    if (rest.stockSize[0] && rest.stockSize[0].cutOrFullSize == 2) {
      setCheckedCutSize(true);
    }

    setSize1x8(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x8 : "0"
    );
    setSize1x9(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x9 : "0"
    );
    setSize1x10(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x10 : "0"
    );
    setSize1x11(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x11 : "0"
    );
    setSize1x12(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x12 : "0"
    );
    setSize1x13(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x13 : "0"
    );
    setSize1x14(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x14 : "0"
    );
    setSize1x15(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s1x15 : "0"
    );
    setSize2x0(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x0 : "0"
    );
    setSize2x1(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x1 : "0"
    );
    setSize2x2(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x2 : "0"
    );
    setSize2x3(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x3 : "0"
    );
    setSize2x4(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x4 : "0"
    );
    setSize2x5(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x5 : "0"
    );
    setSize2x6(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x6 : "0"
    );
    setSize2x7(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x7 : "0"
    );
    setSize2x8(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x8 : "0"
    );
    setSize2x9(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x9 : "0"
    );
    setSize2x10(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x10 : "0"
    );
    setSize2x11(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x11 : "0"
    );
    setSize2x12(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x12 : "0"
    );
    setSize2x13(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x13 : "0"
    );
    setSize2x14(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x14 : "0"
    );
    setSize2x15(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s2x15 : "0"
    );
    setSize3x0(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s3x0 : "0"
    );
    setSize3x1(
      rest.stockSize[0] && rest.stockSize[0] ? rest.stockSize[0].s3x1 : "0"
    );
  }

  useEffect(() => {
    productGet();
    setLoginModal(true);
  }, []);

  const totalPiece = Math.ceil("864" / sets.pcs / box.box);

  // const images = [];
  // const imgActive = [];
  // let val = 1;
  // Object.keys(item).map((i) => {
  //   if (i.includes("design")) {
  //     // console.log(item[i][`image${val}`]);
  //     images.push({
  //       original:
  //         imgApi + "" + item[i][`image${val}`] !==
  //           imgApi + "" + "/crop_img/null"
  //           ? imgApi + "" + item[i][`image${val}`]
  //           : "",
  //       thumbnail:
  //         imgApi + "" + item[i][`image${val}`] !==
  //           imgApi + "" + "/crop_img/null"
  //           ? imgApi + "" + item[i][`image${val}`]
  //           : "",
  //     });
  //     imgActive.push({ active: item[i][`img${val}active`] })
  //     val += 1;
  //   }
  // });

  var indianQty =
    parseInt(size1x8) +
    parseInt(size1x10) +
    parseInt(size1x12) +
    parseInt(size1x14) +
    parseInt(size2x0) +
    parseInt(size2x2) +
    parseInt(size2x4) +
    parseInt(size2x6) +
    parseInt(size2x8) +
    parseInt(size2x10) +
    parseInt(size2x12) +
    parseInt(size2x14) +
    parseInt(size3x0);

  var usQty =
    parseInt(size1x9) +
    parseInt(size1x11) +
    parseInt(size1x13) +
    parseInt(size1x15) +
    parseInt(size2x1) +
    parseInt(size2x3) +
    parseInt(size2x5) +
    parseInt(size2x7) +
    parseInt(size2x9) +
    parseInt(size2x11) +
    parseInt(size2x13) +
    parseInt(size2x15) +
    parseInt(size3x1);

  const cardValInd = item.moq - indianQty;
  const cardValUs = item.moq - usQty;

  const FullAndCutSizeTotal = item.moq - (indianQty + usQty);

  setTimeout(() => {
    // if(size1x8 || size1x10){
    setAnchorElTop(null);
    // }
  }, 4000);

  const handleAddCart = () => {
    const getFindRegister = register.find((f) => f.id === userObj.id);
    const getFindAdmin = admin.find((f) => f.id === getFindRegister.aId);
    const getRegisterClient = registerClient.find(
      (f) => f.regId === userObj.id
    );

    const cgstTax =
      item.gstCash === true
        ? getFindAdmin.state === getFindRegister.state
          ? item.cgst
          : "0"
        : getFindAdmin.state === getFindRegister.state
        ? item.cgst
        : "0";

    const sgstTax =
      item.gstCash === true
        ? getFindAdmin.state === getFindRegister.state
          ? item.sgst
          : "0"
        : getFindAdmin.state === getFindRegister.state
        ? item.sgst
        : "0";

    const igstTax =
      item.gstCash === true
        ? getFindAdmin.state === getFindRegister.state
          ? "0"
          : item.igst
        : getFindAdmin.state === getFindRegister.state
        ? "0"
        : item.igst;

    var per = 100.0;
    var cTax = cgstTax;
    var sTax = sgstTax;
    var iTax = igstTax;
    var localPer = parseFloat(per) + parseFloat(cTax) + parseFloat(sTax);
    var interestedPer = parseFloat(per) + parseFloat(iTax);
    var local = parseFloat(cTax) + parseFloat(sTax);
    var interested = parseFloat(iTax);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        firstName: userObj.firstname,
        lastName: userObj.lastname,
        company: userObj.company,
        metalGram: item.metalGram,
        extraGram: item.extraGram,
        readyGram: item.readyGram,
        golwireThaw: item.golwireThaw,
        itemCodeNumber: item.itemCodeNumber,
        prodManfDetails: item.prodManfDetails,
        prodDescription: item.prodDescription,
        cartDescription: item.cartDescription,
        cartQuantity: item.cartQuantity,
        cartPackCharge: item.cartPackCharge,
        active: item.active,
        gstCash: item.gstCash,
        comment: comment,
        hsnSacCode: item.hsnSacCode,
        cartChild: item.productChild.map((c) => {
          return {
            lbrId: c.lbrId,
            adminId: item.p_adminid,
            price: c.price,
            taxPercentage: c.taxPercentage,
            hsncode: c.hsncode,
            title: c.title,
            parentId: item.productParent.parentId,
          };
        }),
        cartImage: item.productImage.map((ch) => {
          return {
            imgId: ch.imgId,
            adminId: item.p_adminid,
            categoryId: item.productCategory.categoryId,
            parentId: item.productParent.productId,
            cropImageUrl: ch.cropImageUrl,
            cropName: ch.cropName,
          };
        }),
        productSize: {
          adminId: item.p_adminid,
          registerId: getFindRegister.id,
          clientId: getRegisterClient.id,
          cutOrFullSize:
            checkedFullSize === true
              ? checkedCutSize === true
                ? "3"
                : checkedFullSize === true
                ? "1"
                : "0"
              : checkedCutSize === true
              ? "2"
              : "0",
          countrySize: "",
          s1x8: size1x8 ? size1x8 : 0,
          s1x9: size1x9 ? size1x9 : 0,
          s1x10: size1x10 ? size1x10 : 0,
          s1x11: size1x11 ? size1x11 : 0,
          s1x12: size1x12 ? size1x12 : 0,
          s1x13: size1x13 ? size1x13 : 0,
          s1x14: size1x14 ? size1x14 : 0,
          s1x15: size1x15 ? size1x15 : 0,
          s2x0: size2x0 ? size2x0 : 0,
          s2x1: size2x1 ? size2x1 : 0,
          s2x2: size2x2 ? size2x2 : 0,
          s2x3: size2x3 ? size2x3 : 0,
          s2x4: size2x4 ? size2x4 : 0,
          s2x5: size2x5 ? size2x5 : 0,
          s2x6: size2x6 ? size2x6 : 0,
          s2x7: size2x7 ? size2x7 : 0,
          s2x8: size2x8 ? size2x8 : 0,
          s2x9: size2x9 ? size2x9 : 0,
          s2x10: size2x10 ? size2x10 : 0,
          s2x11: size2x11 ? size2x11 : 0,
          s2x12: size2x12 ? size2x12 : 0,
          s2x13: size2x13 ? size2x13 : 0,
          s2x14: size2x14 ? size2x14 : 0,
          s2x15: size2x15 ? size2x15 : 0,
          s3x0: size3x0 ? size3x0 : 0,
          s3x1: size3x1 ? size3x1 : 0,
        },
        taxApply: item.gstCash === true ? true : false,
        saleType: getFindAdmin.state === getFindRegister.state ? "0" : "1",
        cgst:
          item.gstCash === true
            ? getFindAdmin.state === getFindRegister.state
              ? item.cgst
              : "0"
            : getFindAdmin.state === getFindRegister.state
            ? item.cgst
            : "0",
        sgst:
          item.gstCash === true
            ? getFindAdmin.state === getFindRegister.state
              ? item.sgst
              : "0"
            : getFindAdmin.state === getFindRegister.state
            ? item.sgst
            : "0",
        igst:
          item.gstCash === true
            ? getFindAdmin.state === getFindRegister.state
              ? "0"
              : item.igst
            : getFindAdmin.state === getFindRegister.state
            ? "0"
            : item.igst,
        cartCategory: {
          adminId: item.p_adminid,
          categoryId: item.productCategory.categoryId,
          title: item.productCategory.title,
        },
        cartParent: {
          adminId: item.p_adminid,
          parentId: item.productParent.parentId,
          categoryId: item.productParent.categoryId,
          title: item.productParent.title,
        },
        cartSets: {
          adminId: item.p_adminid,
          active: item.productSets.active,
          pcs: item.productSets.pcs,
          setDesc: item.productSets.setDesc,
          setsId: item.productSets.setsId,
        },
        cartRawPrice: {
          adminId: item.p_adminid,
          title: item.productRawPrice.title,
          rawPriceId: item.productRawPrice.rawpriceId,
          price: item.productRawPrice.price,
          different: item.productRawPrice.different,
          taxPercentage: item.productRawPrice.taxPercentage,
        },
        cartBox: {
          boxId: item.productBox.boxId,
          brandName: item.productBox.brandName,
          boxDesc: item.productBox.boxDesc,
          pkgCharge: item.productBox.pkgCharge,
          box: item.productBox.box,
          hsnCode: item.productBox.hsnCode,
          price: item.productBox.price,
          tax_percentage: item.productBox.tax_percentage,
          active: item.productBox.active,
        },
        cartPacking: {
          adminId: item.p_adminid,
          packingMaterialId: item.productPacking.packingmaterialId,
          type: item.productPacking.type,
          pkgdesc: item.productPacking.pkgdesc,
          hsncode: item.productPacking.hsncode,
          price: item.productPacking.price,
          ligst: item.productPacking.ligst,
          active: item.productPacking.active,
        },
        taxsumary: {
          taxableAmount:
            item.gstCash === true
              ? ((item.taxInclude * (indianQty + usQty)) /
                  (getFindAdmin.state === getFindRegister.state
                    ? localPer
                    : interestedPer)) *
                100
              : item.cashPrice * (indianQty + usQty),
          cgst:
            item.gstCash === true
              ? item.taxApply === false
                ? 0
                : item.taxApply === true
                ? getFindAdmin.state === getFindRegister.state
                  ? (((item.taxInclude * (indianQty + usQty)) /
                      (getFindAdmin.state === getFindRegister.state
                        ? localPer
                        : interestedPer)) *
                      (getFindAdmin.state === getFindRegister.state
                        ? local
                        : interested)) /
                    2
                    ? (((item.taxInclude * (indianQty + usQty)) /
                        (getFindAdmin.state === getFindRegister.state
                          ? localPer
                          : interestedPer)) *
                        (getFindAdmin.state === getFindRegister.state
                          ? local
                          : interested)) /
                      2
                    : 0
                  : 0
                : 0
              : getFindAdmin.state === getFindRegister.state
              ? (item.cashPrice * (indianQty + usQty) * local) / 100 / 2
              : 0,
          sgst:
            item.gstCash === true
              ? item.taxApply === false
                ? 0
                : item.taxApply === true
                ? getFindAdmin.state === getFindRegister.state
                  ? (((item.taxInclude * (indianQty + usQty)) /
                      (getFindAdmin.state === getFindRegister.state
                        ? localPer
                        : interestedPer)) *
                      (getFindAdmin.state === getFindRegister.state
                        ? local
                        : interested)) /
                    2
                    ? (((item.taxInclude * (indianQty + usQty)) /
                        (getFindAdmin.state === getFindRegister.state
                          ? localPer
                          : interestedPer)) *
                        (getFindAdmin.state === getFindRegister.state
                          ? local
                          : interested)) /
                      2
                    : 0
                  : 0
                : 0
              : getFindAdmin.state === getFindRegister.state
              ? (item.cashPrice * (indianQty + usQty) * local) / 100 / 2
              : 0,
          igst:
            item.gstCash === true
              ? item.taxApply === false
                ? 0
                : item.taxApply === true
                ? getFindAdmin.state !== getFindRegister.state
                  ? ((item.taxInclude * (indianQty + usQty)) /
                      (getFindAdmin.state !== getFindRegister.state
                        ? interestedPer
                        : localPer)) *
                    (getFindAdmin.state === getFindRegister.state
                      ? local
                      : interested)
                    ? ((item.taxInclude * (indianQty + usQty)) /
                        (getFindAdmin.state !== getFindRegister.state
                          ? interestedPer
                          : localPer)) *
                      (getFindAdmin.state === getFindRegister.state
                        ? local
                        : interested)
                    : 0
                  : 0
                : 0
              : getFindAdmin.state !== getFindRegister.state
              ? (item.cashPrice * (indianQty + usQty) * interested) / 100
              : 0,
          totalTax:
            item.gstCash === true
              ? getFindAdmin.state === getFindRegister.state
                ? ((((item.gstCash === true ? item.taxInclude : "0") *
                    (indianQty + usQty)) /
                    (getFindAdmin.state === getFindRegister.state
                      ? localPer
                      : interestedPer)) *
                    (getFindAdmin.state === getFindRegister.state
                      ? local
                      : interested)) /
                    2 +
                  ((((item.gstCash === true ? item.taxInclude : "0") *
                    (indianQty + usQty)) /
                    (getFindAdmin.state === getFindRegister.state
                      ? localPer
                      : interestedPer)) *
                    (getFindAdmin.state === getFindRegister.state
                      ? local
                      : interested)) /
                    2
                : (((item.gstCash === true ? item.taxInclude : "0") *
                    (indianQty + usQty)) /
                    (getFindAdmin.state === getFindRegister.state
                      ? localPer
                      : interestedPer)) *
                  (getFindAdmin.state === getFindRegister.state
                    ? local
                    : interested)
              : getFindAdmin.state === getFindRegister.state
              ? (item.cashPrice * (indianQty + usQty) * local) / 100
              : (item.cashPrice * (indianQty + usQty) * interested) / 100,
          taxType:
            getFindAdmin.state === getFindRegister.state
              ? item.cgst
              : item.igst,
          adminId: item.p_adminid,
        },
      }),
    };

    fetch(
      localApi +
        `/cart/add/${item.p_adminid}/${item.id}/${getRegisterClient.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Added Succesfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    // window.location.reload(false);
    history.push("/add-cart");
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Impturkey Product</title>
        <meta
          name="description"
          content="Buy your favourite Gold plated bangles Online at best prices in India only at bangles.org. Using online shopping resources also help you compare prices and research products. Our product range includes customised bangles & bracelets, earrings & finger rings, Watches & men’s Sikh Kada and more.  Online shopping is not only convenient, but it can also be a great option for finding the best deals."
        />
        <meta name="keywords" content="Gold plated bangles" />
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        color="info"
        // changeColorOnScroll={{
        //     height: 100,
        //     color: "info",
        // }}
      />
      <Parallax
        image={require("assets/img/ban.webp").default}
        filter="info"
        className={classes.pageHeader}
      ></Parallax>

      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={
                classeAlert.button + " " + classeAlert.success
              }
            />
          ) : null}
          <div className={classNames(classes.main, classes.mainRaised)}>
            {!activeSession ? (
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Dialog
                  classes={{
                    root: classPop.modalRoot,
                    paper: classPop.modal + " " + classPop.modalLogin,
                  }}
                  open={loginModal}
                  TransitionComponent={Transition}
                  keepMounted
                  // onClose={() => setLoginModal(false)}
                  aria-labelledby="login-modal-slide-title"
                  aria-describedby="login-modal-slide-description"
                >
                  <Card plain className={classPop.modalLoginCard}>
                    <DialogTitle
                      id="login-modal-slide-title"
                      disableTypography
                      className={classPop.modalHeader}
                    >
                      <CardHeader
                        plain
                        color="info"
                        className={
                          classPop.textCenter + " " + classPop.cardLoginHeader
                        }
                      >
                        <Button
                          simple
                          className={classPop.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          onClick={() => {
                            setLoginModal(false);
                            history.push(
                              `/bangles-size-mm-list?catdesc=${catdesc}&cat1desc=${cat1desc}&Plating=Gold%20Plating`
                            );
                          }}
                        >
                          {" "}
                          <Close className={classPop.modalClose} />
                        </Button>
                        <h5 className={classPop.cardTitleWhite}>Log in</h5>
                        <div className={classPop.socialLine}>
                          <Button
                            justIcon
                            link
                            className={classPop.socialLineButton}
                          >
                            <i className="fab fa-facebook-square" />
                          </Button>
                          <Button
                            justIcon
                            link
                            className={classPop.socialLineButton}
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                          <Button
                            justIcon
                            link
                            className={classPop.socialLineButton}
                          >
                            <i className="fab fa-google-plus-g" />
                          </Button>
                        </div>
                      </CardHeader>
                    </DialogTitle>
                    <DialogContent
                      id="login-modal-slide-description"
                      className={classPop.modalBody}
                    >
                      {!otpScreen ? (
                        <form>
                          <p
                            className={
                              classPop.description + " " + classPop.textCenter
                            }
                          >
                            Welcome
                          </p>
                          <CardBody className={classPop.cardLoginBody}>
                            <GridContainer justify="center">
                              {/* <GridItem xs={12} sm={12} md={12}> */}
                              <NavPills
                                color="info"
                                alignCenter
                                tabs={[
                                  {
                                    tabButton: "Mobile",
                                    tabIcon: PhoneAndroidIcon,
                                    tabContent: (
                                      <Card>
                                        <CardBody>
                                          <PhoneInput
                                            inputStyle={{
                                              width: "100%",
                                              border: "none",
                                              borderBottom:
                                                "1px solid #040000 ",
                                              borderRadius: "0",
                                            }}
                                            country={"in"}
                                            name="mobile"
                                            value={mobile}
                                            onChange={(mobile) => {
                                              handleMobileNumber(mobile);
                                            }}
                                            required={true}
                                          />
                                          <span style={{ color: "red" }}>
                                            {mobileErr}
                                          </span>
                                        </CardBody>
                                        <CardFooter
                                          className={
                                            classe.justifyContentCenter
                                          }
                                        >
                                          <Button
                                            color="info"
                                            simple
                                            // size="lg"
                                            block
                                            onClick={handleSendOtpWithMobile}
                                          >
                                            Login
                                          </Button>
                                        </CardFooter>
                                      </Card>
                                    ),
                                  },
                                  {
                                    tabButton: "Email",
                                    tabIcon: EmailIcon,
                                    tabContent: (
                                      <Card>
                                        <CardBody>
                                          <CustomInput
                                            formControlProps={{
                                              fullWidth: true,
                                              className:
                                                classes.customFormControlClasses,
                                            }}
                                            inputProps={{
                                              onChange: (e) => handleEmail(e),
                                              id: "email",
                                              value: email,
                                              name: "email",
                                              placeholder: "Email...",
                                            }}
                                            required={true}
                                          />
                                          <span style={{ color: "red" }}>
                                            {emailErr}
                                          </span>
                                        </CardBody>
                                        <CardFooter
                                          className={
                                            classe.justifyContentCenter
                                          }
                                        >
                                          <Button
                                            color="info"
                                            simple
                                            // size="lg"
                                            block
                                            onClick={handleSendOtpWithEmail}
                                          >
                                            Login
                                          </Button>
                                        </CardFooter>
                                      </Card>
                                    ),
                                  },
                                ]}
                              />
                              {/* </GridItem> */}
                            </GridContainer>
                          </CardBody>
                          <br />
                          <div className={classes.textCenter}>
                            <Button
                              simple
                              color="info"
                              // size="lg"
                              href="/bangles-sign-up"
                            >
                              Create New Account
                            </Button>
                          </div>
                        </form>
                      ) : (
                        <form>
                          <p
                            className={
                              classPop.description + " " + classPop.textCenter
                            }
                          >
                            6 digit OTP SMS sent on your mobile
                          </p>
                          <p
                            className={
                              classPop.description + " " + classPop.textCenter
                            }
                          >
                            {otp}
                          </p>
                          <p
                            className={
                              classPop.description + " " + classPop.textCenter
                            }
                          >
                            {reSend}
                          </p>
                          <CardBody className={classPop.cardLoginBody}>
                            <CustomInput
                              id="otpNumber"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                name: "otpNumber",
                                value: otpNumber,
                                onChange: (e) => handleOtp(e),
                                placeholder: "Enter OTP here",
                                type: "text",
                                // endAdornment: (
                                //   <InputAdornment position="end">
                                //     <Icon className={classes.inputIconsColor}>
                                //       lock_utline
                                //     </Icon>
                                //   </InputAdornment>
                                // ),
                                autoComplete: "off",
                              }}
                              required={true}
                            />
                            <p
                              className={
                                classes.description + " " + classes.textCenter
                              }
                              style={{ color: "red" }}
                            >
                              {otpNumberErr}
                            </p>
                          </CardBody>
                          <div className={classes.textCenter}>
                            <Button
                              simple
                              color="info"
                              // size="lg"
                              onClick={
                                mobile
                                  ? handleLoginWithMobile
                                  : handleLoginWithEmail
                              }
                            >
                              Log in
                            </Button>
                            <Button
                              simple
                              color="info"
                              // size="lg"
                              // onClick={handleCall}
                            >
                              <FiPhoneCall />
                              Call Me
                            </Button>
                          </div>
                          <p
                            className={
                              classes.description + " " + classes.textCenter
                            }
                          >
                            {" "}
                            Didn't receive the OTP yet?{" "}
                            <span style={{ marginLeft: "10px" }}>
                              <a
                                // href="#"
                                onClick={
                                  mobile
                                    ? handleSendOtpWithMobile
                                    : handleSendOtpWithEmail
                                }
                              >
                                Resend OTP
                              </a>
                            </span>
                          </p>
                        </form>
                      )}
                    </DialogContent>
                  </Card>
                </Dialog>
              </GridItem>
            ) : (
              ""
            )}
            <GridContainer>
              <GridItem md={6} sm={12} xs={12}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={0}
                  showIndex={true}
                  // autoPlay={true}
                  // items={images.filter((item) => item.original !== "")}
                  items={imageGal}
                  showThumbnails={true}
                  renderLeftNav={(onClick, disabled) => {
                    return (
                      <button
                        className="image-gallery-left-nav"
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                  renderRightNav={(onClick, disabled) => {
                    return (
                      <button
                        className="image-gallery-right-nav"
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem md={6} sm={12} xs={12}>
                {/* <h2 className={classes.title}>Becky Silk Blazer</h2> */}
                <GridContainer>
                  <GridItem md={6} sm={6} xs={12}>
                    <span className={classes.mainPrice}>
                      <strong>
                        Item Code: {item.itemCodeNumber + "-" + item.id}
                      </strong>
                    </span>
                  </GridItem>
                  <GridItem md={6} sm={6} xs={12}>
                    <span className={classes.mainPrice}>
                      <strong>
                        {item && item.gstCash === true ? (
                          <>
                            <h5>
                              <span style={{ color: "red" }}>
                                ₹ {(item && item.taxInclude) + " Inc."}
                              </span>
                            </h5>
                          </>
                        ) : (
                          <>
                            <h5>
                              <span style={{ color: "black" }}>
                                ₹ {item && item.cashPrice}
                              </span>
                            </h5>
                          </>
                        )}
                        {/* ₹ {activeSession ? Number(calculate.cash).toFixed(2) + "/-" : Number(calculate.taxincl).toFixed(2) + "/-"}{" "}
                        {!activeSession ? (
                          calculate.profitactive === 1 ? (
                            calculate.cash_gst === 1 ? (
                              <h5>
                                <span style={{ color: "red" }}>
                                  Sets ₹{" "}
                                  {(calculate.taxincl / box.box).toFixed(2)}/-
                                </span>
                              </h5>
                            ) : (
                              <h5>
                                Sets{" "}
                                <span>
                                  ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                                </span>
                              </h5>
                            )
                          ) : calculate.cash_gst === 1 ? (
                            <h5>
                              <span style={{ color: "#9c27b0" }}>
                                Sets ₹{" "}
                                {(calculate.taxincl / box.box).toFixed(2)}/-
                              </span>
                            </h5>
                          ) : (
                            <h5>
                              Sets{" "}
                              <span>
                                ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                              </span>
                            </h5>
                          )
                        ) : (
                          ""
                        )} */}
                      </strong>
                    </span>
                    <span className={classes.mainPrice}>
                      {/* {activeSession ? (
                        calculate.profitactive === 1 ? (
                          <h5>
                            Sets ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                          </h5>
                        ) : (
                          <h5>
                            Sets ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                          </h5>
                        )
                      ) : (
                        ""
                      )} */}
                    </span>
                  </GridItem>
                </GridContainer>
                <h5 className={classes.mainPrice}>
                  {cat.title +
                    "/" +
                    catMM.title +
                    "/ " +
                    (item.readyGram * sets.pcs).toFixed(4) +
                    " Avg Gram of " +
                    sets.setDesc +
                    " and " +
                    box.boxDesc +
                    ", " +
                    item.cartDescription}
                </h5>
                {/* <h5 className={classes.mainPrice}>
                  <Button round color="info">
                    CASH BACK ₹ 0.00/-
                  </Button>
                </h5>
                <h5 className={classes.mainPrice} style={{ color: "red" }}>
                  The Offer is applicable for a successful transaction of
                  purchase of a product and It will credited in your Wallet
                  after 30 days from purchase date.
                </h5> */}
                <h6 className={classes.mainPrice}>
                  Minimum order: should be for {item.moq} Box {"{"}
                  {sets.setDesc + " and " + box.boxDesc}
                  {"}"}. Product Delivery within 25 working days in India Please
                  feel free to Contact Us @ 022-40128886 {"/"} +91 9967457786{" "}
                  {"/"} +91 9967427786
                </h6>
                <h5
                  style={{
                    backgroundColor: "#00acc1",
                    width: "30%",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {proTotal > 0 ? "In Stock" : "Make To Order"}
                </h5>
                <Accordion
                  active={0}
                  activeColor="info"
                  collapses={[
                    {
                      title: "Size Charts",
                      content: (
                        <span>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <Tooltip
                                id="tooltip-top"
                                title="Click Here for Size Chart"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                {/* <h6 className={classes.title}> */}
                                <a href="/bangles-size-guide">
                                  Jeans Size Chart
                                </a>
                                {/* </h6> */}
                              </Tooltip>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={checkedFullSize}
                                    onChange={(event) =>
                                      setCheckedFullSize(event.target.checked)
                                    }
                                    value="checkedFullSize"
                                    classes={{
                                      switchBase: classe.switchBase,
                                      checked: classe.switchChecked,
                                      thumb: classe.switchIcon,
                                      track: classe.switchBar,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classe.label,
                                  root: classe.labelRoot,
                                }}
                                label={
                                  checkedFullSize === false ? (
                                    <span>Full Size</span>
                                  ) : (
                                    <span
                                      style={{
                                        color:
                                          checkedFullSize === true
                                            ? "#00acc1"
                                            : "",
                                      }}
                                    >
                                      Full Size
                                    </span>
                                  )
                                }
                              />
                            </GridItem>
                          </GridContainer>

                          {checkedFullSize === true ? (
                            <GridContainer>
                              {/* <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="1x8"
                                  id="1x8"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x8,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x8(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x8("0");
                                      } else if (value === "") {
                                        setSize1x8("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x8(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                                <Popover
                                  classes={{
                                    paper: classPop.popover,
                                  }}
                                  open={Boolean(anchorElTop)}
                                  anchorEl={anchorElTop}
                                  onClose={() => setAnchorElTop(null)}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                >
                                  <div
                                    className={
                                      classPop.popoverBody +
                                      " " +
                                      classes.textCenter
                                    }
                                  >
                                    Quantity should be {totalPiece} or greater
                                    than {totalPiece} Boxes in Any Size.
                                  </div>
                                </Popover>
                              </GridItem> */}
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="28"
                                  id="1x10"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x10,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x10(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x10("0");
                                      } else if (value === "") {
                                        setSize1x10("0");
                                      } else if (value === value) {
                                        setAnchorElTop(e.currentTarget);
                                        // console.log(parseInt("2") + parseInt(value));
                                        setSize1x10("1" * value);
                                        setSize1x12("1" * value);
                                        setSize1x14("2" * value);
                                        setSize2x0("1" * value);
                                        setSize2x2("1" * value);
                                        setSize2x4("0" * value);
                                        setSize2x6("0" * value);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                                <Popover
                                  classes={{
                                    paper: classPop.popover,
                                  }}
                                  open={Boolean(anchorElTop)}
                                  anchorEl={anchorElTop}
                                  onClose={() => setAnchorElTop(null)}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                >
                                  <div
                                    className={
                                      classPop.popoverBody +
                                      " " +
                                      classes.textCenter
                                    }
                                  >
                                    Quantity should be {totalPiece} or greater
                                    than {totalPiece} Boxes in Any Size.
                                  </div>
                                </Popover>
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="30"
                                  id="1x12"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x12,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x12(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x12("0");
                                      } else if (value === "") {
                                        setSize1x12("0");
                                      } else if (value === value) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x10("0" * value);
                                        setSize1x12("1" * value);
                                        setSize1x14("1" * value);
                                        setSize2x0("1" * value);
                                        setSize2x2("1" * value);
                                        setSize2x4("1" * value);
                                        setSize2x6("1" * value);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="32"
                                  id="1x14"
                                  number
                                  disabled={true}
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x14,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x14(value);
                                    },
                                    // onBlur: (e) => {
                                    //   const { value } = e.target;
                                    //   if (value === "0") {
                                    //     setSize1x14("0");
                                    //   } else if (value === "") {
                                    //     setSize1x14("0");
                                    //   } else if (value < totalPiece) {
                                    //     setAnchorElTop(e.currentTarget);
                                    //     setSize1x14(totalPiece);
                                    //   }
                                    // },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="34"
                                  id="2x0"
                                  number
                                  disabled={true}
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x0,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x0(value);
                                    },
                                    // onBlur: (e) => {
                                    //   const { value } = e.target;
                                    //   if (value === "0") {
                                    //     setSize2x0("0");
                                    //   } else if (value === "") {
                                    //     setSize2x0("0");
                                    //   } else if (value < totalPiece) {
                                    //     setAnchorElTop(e.currentTarget);
                                    //     setSize2x0(totalPiece);
                                    //   }
                                    // },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="36"
                                  id="2x2"
                                  number
                                  disabled={true}
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x2,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x2(value);
                                    },
                                    // onBlur: (e) => {
                                    //   const { value } = e.target;
                                    //   if (value === "0") {
                                    //     setSize2x2("0");
                                    //   } else if (value === "") {
                                    //     setSize2x2("0");
                                    //   } else if (value < totalPiece) {
                                    //     setAnchorElTop(e.currentTarget);
                                    //     setSize2x2(totalPiece);
                                    //   }
                                    // },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="38"
                                  id="2x4"
                                  number
                                  disabled={true}
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x4,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x4(value);
                                    },
                                    // onBlur: (e) => {
                                    //   const { value } = e.target;
                                    //   if (value === "0") {
                                    //     setSize2x4("0");
                                    //   } else if (value === "") {
                                    //     setSize2x4("0");
                                    //   } else if (value < totalPiece) {
                                    //     setAnchorElTop(e.currentTarget);
                                    //     setSize2x4(totalPiece);
                                    //   }
                                    // },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="40"
                                  id="2x6"
                                  number
                                  disabled={true}
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x6,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x6(value);
                                    },
                                    // onBlur: (e) => {
                                    //   const { value } = e.target;
                                    //   if (value === "0") {
                                    //     setSize2x6("0");
                                    //   } else if (value === "") {
                                    //     setSize2x6("0");
                                    //   } else if (value < totalPiece) {
                                    //     setAnchorElTop(e.currentTarget);
                                    //     setSize2x6(totalPiece);
                                    //   }
                                    // },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              {/* <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x8"
                                  id="2x8"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x8,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x8(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x8("0");
                                      } else if (value === "") {
                                        setSize2x8("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x8(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x10"
                                  id="2x10"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x10,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x10(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x10("0");
                                      } else if (value === "") {
                                        setSize2x10("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x10(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x12"
                                  id="2x12"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x12,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x12(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x12("0");
                                      } else if (value === "") {
                                        setSize2x12("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x12(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x14"
                                  id="2x14"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x14,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x14(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x14("0");
                                      } else if (value === "") {
                                        setSize2x14("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x14(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="3x0"
                                  id="3x0"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size3x0,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize3x0(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize3x0("0");
                                      } else if (value === "") {
                                        setSize3x0("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize3x0(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem> */}
                            </GridContainer>
                          ) : (
                            ""
                          )}

                          {/* <FormControlLabel
                            control={
                              <Switch
                                checked={checkedCutSize}
                                onChange={(event) =>
                                  setCheckedCutSize(event.target.checked)
                                }
                                value="checkedCutSize"
                                classes={{
                                  switchBase: classe.switchBase,
                                  checked: classe.switchChecked,
                                  thumb: classe.switchIcon,
                                  track: classe.switchBar,
                                }}
                              />
                            }
                            classes={{
                              label: classe.label,
                              root: classe.labelRoot,
                            }}
                            label={
                              checkedCutSize === false ? (
                                <span>Cut Size</span>
                              ) : (
                                <span
                                  style={{
                                    color:
                                      checkedCutSize === true ? "#00acc1" : "",
                                  }}
                                >
                                  Cut Size
                                </span>
                              )
                            }
                          /> */}

                          {/* {checkedCutSize === true ? (
                            <GridContainer>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="1x9"
                                  id="1x9"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x9,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x9(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x9("0");
                                      } else if (value === "") {
                                        setSize1x9("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x9(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                                <Popover
                                  classes={{
                                    paper: classPop.popover,
                                  }}
                                  open={Boolean(anchorElTop)}
                                  anchorEl={anchorElTop}
                                  onClose={() => setAnchorElTop(null)}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                >
                                  <div
                                    className={
                                      classPop.popoverBody +
                                      " " +
                                      classes.textCenter
                                    }
                                  >
                                    Quantity should be {totalPiece} or greater
                                    than {totalPiece} Boxes in Any Size.
                                  </div>
                                </Popover>
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="1x11"
                                  id="1x11"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x11,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x11(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x11("0");
                                      } else if (value === "") {
                                        setSize1x11("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x11(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="1x13"
                                  id="1x13"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x13,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x13(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x13("0");
                                      } else if (value === "") {
                                        setSize1x13("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x13(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="1x15"
                                  id="1x15"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size1x15,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize1x15(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize1x15("0");
                                      } else if (value === "") {
                                        setSize1x15("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize1x15(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x1"
                                  id="2x1"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x1,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x1(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x1("0");
                                      } else if (value === "") {
                                        setSize2x1("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x1(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x3"
                                  id="2x3"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x3,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x3(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x3("0");
                                      } else if (value === "") {
                                        setSize2x3("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x3(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x5"
                                  id="2x5"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x5,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x5(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x5("0");
                                      } else if (value === "") {
                                        setSize2x5("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x5(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x7"
                                  id="2x7"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x7,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x7(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x7("0");
                                      } else if (value === "") {
                                        setSize2x7("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x7(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} sm={4} md={4}>
                                <CustomInput
                                  labelText="2x9"
                                  id="2x9"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x9,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x9(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x9("0");
                                      } else if (value === "") {
                                        setSize2x9("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x9(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x11"
                                  id="2x11"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x11,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x11(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x11("0");
                                      } else if (value === "") {
                                        setSize2x11("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x11(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x13"
                                  id="2x13"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x13,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x13(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x13("0");
                                      } else if (value === "") {
                                        setSize2x13("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x13(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="2x15"
                                  id="2x15"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size2x15,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize2x15(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize2x15("0");
                                      } else if (value === "") {
                                        setSize2x15("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize2x15(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                  labelText="3x1"
                                  id="3x1"
                                  number
                                  inputProps={{
                                    placeholder: totalPiece,
                                    value: size3x1,
                                    onChange: (e) => {
                                      const { value } = e.target;
                                      setSize3x1(value);
                                    },
                                    onBlur: (e) => {
                                      const { value } = e.target;
                                      if (value === "0") {
                                        setSize3x1("0");
                                      } else if (value === "") {
                                        setSize3x1("0");
                                      } else if (value < totalPiece) {
                                        setAnchorElTop(e.currentTarget);
                                        setSize3x1(totalPiece);
                                      }
                                    },
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          ) : (
                            ""
                          )} */}

                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <p style={{ color: "#00acc1" }}>
                                The minimum quantity required 5 in packet
                              </p>
                              <p style={{ color: "#00acc1" }}>
                                moq {FullAndCutSizeTotal}
                              </p>
                              {/* <p style={{ color: "#00acc1" }}>
                                The minimum quantity is {totalPiece} box in one
                                size
                              </p>
                              <p style={{ color: "red" }}>
                                Full Size Total {indianQty} & Cut Size Total{" "}
                                {usQty}
                              </p> */}
                              <p style={{ color: "red" }}>
                                Total quantity : {indianQty + usQty} & Order
                                Value ₹{" "}
                                {item.gstCash === true
                                  ? (
                                      item.taxInclude *
                                      (indianQty + usQty)
                                    ).toFixed(2)
                                  : (
                                      item.cashPrice *
                                      (indianQty + usQty)
                                    ).toFixed(2)}
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                labelText="Comment"
                                id="comment"
                                number
                                inputProps={{
                                  placeholder: "Comment",
                                  value: comment,
                                  onChange: (e) => setComment(e.target.value),
                                }}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </span>
                      ),
                    },
                    {
                      title: "Product Details and Care",
                      content: (
                        <ul>
                          <li>
                            Durability only for a period of (6) months from the
                            date of sale(Not for spoilage or breakage invalid if
                            not stamped).
                          </li>
                          <li>
                            Please wash this piece with soap and warm water &
                            wipe it with soft cotton cloth for longer life.
                          </li>
                        </ul>
                      ),
                    },
                  ]}
                />
                {/* item.moq < indianQty + usQty  */}
                {item.moq < indianQty + usQty ? (
                  <GridContainer className={classes.pullRight}>
                    <Button round color="info" onClick={handleAddCart}>
                      Add to Cart &nbsp; <ShoppingCart />
                    </Button>
                  </GridContainer>
                ) : (
                  <GridContainer className={classes.pullRight}>
                    <Button round onClick={handleAddCart} disabled={true}>
                      Add to Cart &nbsp; <ShoppingCart />
                    </Button>
                  </GridContainer>
                )}
              </GridItem>
            </GridContainer>
          </div>
          <div className={classNames(classes.features, classes.textCenter)}>
            <GridContainer>
              <GridItem md={3} sm={6}>
                <InfoArea
                  title="DELEIVERY POLICY"
                  description="The order will be delivered within 25 working days in India from the date of payment and it will take minimum 90 working days to be delivered outside India."
                  icon={LocalShipping}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem md={3} sm={6}>
                <InfoArea
                  title="REFUND POLICY"
                  description="You are liable to get the full refund only after the returns of goods within 30 days of purchase, in case of goods received of different quality, different design or different size."
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem md={3} sm={6}>
                <InfoArea
                  title="POPULAR ITEMS"
                  description="Our 5MM and 8MM products are best sellers in India as well as foreign countries like  Bangladesh, USA,UAE and, Iran. Some of our popular brands are Omega Bangles, Royal Arts Bangles, Madam Asmi and Evershine bangles is the most liked brand, it sold in almost every state of India."
                  icon={Favorite}
                  iconColor="rose"
                  vertical
                />
              </GridItem>
              <GridItem md={3} sm={6}>
                <InfoArea
                  title="RETURN POLICY"
                  description="We offer a lifetime return policy on all purchases within India. The product along with the Bill can be returned on its current market value. We will give the fresh lot of bangles in return for the products with deductions towards making charges and processing charges."
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              Popular items Today:
            </h3>
            <GridContainer>
              {prodList &&
                prodList.map((prod) => (
                  <GridItem sm={6} md={3} key={prod.id}>
                    <Card product>
                      <CardHeader image>
                        {/* <CornerRibbon
                          position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="#00acc1" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        > {prod.total > 0 ? "In Stock" : "Make To Order"}
                        </CornerRibbon> */}
                        <a
                          href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                          // &Plating=${prod.plating.description.slice(8)}
                        >
                          {/* <img src={cardProduct1} alt="cardProduct" /> */}
                          {/* {prod.design1.img1active === 1 ? (
                            <img src={imgApi + "" + prod.design1.image1} alt="..." />
                          ) : prod.design2.img2active === 1 ? (
                            <img src={imgApi + "" + prod.design2.image2} alt="..." />
                          ) : prod.design3.img3active === 1 ? (
                            <img src={imgApi + "" + prod.design3.image3} alt="..." />
                          ) : prod.design4.img4active === 1 ? (
                            <img src={imgApi + "" + prod.design4.image4} alt="..." />
                          ) : prod.design5.img5active === 1 ? (
                            <img src={imgApi + "" + prod.design5.image5} alt="..." />
                          ) : prod.design6.img6active === 1 ? (
                            <img src={imgApi + "" + prod.design6.image6} alt="..." />
                          ) : (
                            ""
                          )} */}
                          <img
                            src={
                              prod.productImage[0].cropImageUrl +
                              "?id=" +
                              prod.productImage[0].imgId
                            }
                            alt="..."
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "65%",
                              left: "50%",
                              width: "65px",
                              height: "65px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="logo1" />
                            {/* {imgApi + "" + prod.design1.image1 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design2.image2 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design3.image3 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design4.image4 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design5.image5 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design6.image6 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : (
                              ""
                            )} */}
                          </p>
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h6
                          className={classNames(
                            classes.cardCategory,
                            classes.textRose
                          )}
                        >
                          {prod.productCategory.title +
                            " " +
                            prod.productParent.title}{" "}
                          <br /> {prod.itemCodeNumber}-{prod.id}
                        </h6>
                        <h4 className={classes.cardTitle}>
                          {prod.prodDescription}
                        </h4>
                        <div className={classes.cardDescription}>
                          {(prod.readyGram * prod.productSets.pcs).toFixed(4)}{" "}
                          Avg Gram Of {prod.productSets.setDesc} {"and "}
                          {prod.productBox.boxDesc}
                          {","} {prod.cartDescription}
                          {"."}
                        </div>
                      </CardBody>
                      <CardFooter className={classes.justifyContentBetween}>
                        <div className={classes.price}>
                          {prod.gstCash === true ? (
                            <>
                              <h5>
                                <span style={{ color: "red" }}>
                                  Price ₹ {prod.taxInclude.toFixed(2) + " Inc."}
                                </span>
                              </h5>
                            </>
                          ) : (
                            <>
                              <h5>
                                <span style={{ color: "black" }}>
                                  Price ₹ {prod.cashPrice.toFixed(2)}
                                </span>
                              </h5>
                            </>
                          )}

                          {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5>Price <span style={{ color: "red" }}>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>}

                                                {!activeSession ? <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                </h6> : prod.taxCalculation.profitactive === 1 ? <h6>Price <span style={{ textDecoration: "line-through", color: "red" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6>Price <span style={{ textDecoration: "line-through" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6>
                                                } */}

                          {/* {!activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              prod.taxCalculation.cash_gst === 1 ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹{" "}
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /-
                                    </span>
                                  </h5>{" "}
                                  <h6>
                                    <a href="/bangles-login">Login</a> to see
                                    prices and exciting offers.
                                  </h6>{" "}
                                </>
                              ) : (
                                <h5>
                                  Price{" "}
                                  <span>
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </span>
                                </h5>
                              )
                            ) : prod.taxCalculation.cash_gst === 1 ? (
                              <>
                                <h5>
                                  <span style={{ color: "#9c27b0" }}>
                                    Price ₹{" "}
                                    {(
                                      prod.taxCalculation.taxincl / prod.box.box
                                    ).toFixed(2)}
                                    /-
                                  </span>
                                </h5>{" "}
                                <h6>
                                  <a href="/bangles-login">Login</a> to see
                                  prices and exciting offers.
                                </h6>
                              </>
                            ) : (
                              <h5>
                                Price{" "}
                                <span>
                                  ₹{" "}
                                  {(
                                    prod.taxCalculation.cash / prod.box.box
                                  ).toFixed(2)}
                                  /-*
                                </span>
                              </h5>
                            )
                          ) : (
                            ""
                          )} */}

                          {/* {activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            ) : (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#9c27b0",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            )
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className={classes.stats}>
                          <Tooltip
                            id="tooltip-top"
                            title="Save to Wishlist"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button justIcon color="rose" simple>
                              <Favorite />
                            </Button>
                          </Tooltip>
                        </div>
                      </CardFooter>
                      <CardFooter className={classes.justifyContentBetween}>
                        <div className={classes.price}>
                          <Button
                            color="info"
                            href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                            // &Plating=${prod.plating.description.slice(8)}
                          >
                            {/* <Subject /> */}
                            Buy Now
                          </Button>
                        </div>
                        <div>
                          {/* <Button
                            color="success"
                            round
                            justIcon
                            href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp
                              }&text=https://bangles.org.in/bangles-product?id=${prod.id
                              }%20${prod.parent.title
                              }%20${prod.plating.description.slice(
                                8
                              )}&app_absent=0`}
                          >
                            <i
                              className="fab fa-whatsapp"
                              style={{ fontSize: "30px" }}
                            />
                          </Button> */}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                ))}
            </GridContainer>
          </div>
        </div>
      </div>
      <BanglesFooter />
    </div>
  );
}
