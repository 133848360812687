/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import { MdUpdate } from "react-icons/md";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress"
import Slide from "@material-ui/core/Slide";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
// import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import BanglesHeader from "views/BanglesPage/Section/BanglesHeader"
import HeaderLink from "views/BanglesPage/Section/BanglesHeaderLink"
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter"
import logo from "assets/img/examples/apple-bangles-org-icon.png"

import kada1 from "assets/img/faces/jeans4.jpeg";
import polaBangles2 from "assets/img/faces/jeans12.jpeg";
import fourPieces3 from "assets/img/faces/jeans6.jpeg";
import sixPieces4 from "assets/img/faces/jeans8.jpeg";
import sevenPieces5 from "assets/img/faces/Shirt6.jpeg";
import eightPieces6 from "assets/img/faces/Shirt10.jpeg";
import twevelPieces7 from "assets/img/faces/Shirt2.jpeg";
import twentyFourPieces8 from "assets/img/faces/Shirt11.jpeg";
import axios from "axios";

const useStyles = makeStyles(productStyle);
const useStyl = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function OfflineBanglesProductList() {
    const [prodList, setProdList] = React.useState([]);
    const [page, setpage] = React.useState(0);
    const [loginModal, setLoginModal] = React.useState(false);
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const [prodDetails, setProdDetails] = React.useState("");
  const [prodDetailsChild, setProdDetailsChild] = React.useState("");
  const [prodDetailsChild1, setProdDetailsChild1] = React.useState("");
  const [prodDetailsChild2, setProdDetailsChild2] = React.useState("");
  const [prodDetailsRaw, setProdDetailsRaw] = React.useState("");
  const [prodDetailsSets, setProdDetailsSets] = React.useState("");
  const [prodDetailsBox, setProdDetailsBox] = React.useState("");

    const classes = useStyles();
    const classe = useStyl();
    const classPop = useStylPop();

    const activeSession = sessionStorage.getItem("sessionData");
    // const headApi = `https://api.bangles.org.in/api/rest`;
    // const imgApi = `https://imgstore.bangles.org.in`;

    const fetchData = async () => {
        setpage(page + 1);
        axios.get(localApi + `/product/list/byLimit?adminId=4&page=${page}&size=4`)
            .then(res => setProdList([...prodList, ...res.data]))
    }

    useEffect(() => {
        fetchData();
        setTimeout(hideAlertPop, 2000);
        setLoginModal(true)
    }, []);

    const hideAlertPop = () => {
        setLoginModal(false)
    };

    // var lbrprice = 0;
    var boxlbrprice = 0;
    var metamt = 0;
    var metprice = 0;
    var boxmetgram = 0;
    var metgram = 0;
    var pltprice = 0;
    var boxpltamt = 0;
    var metgramamt = 0;

    return (
        <div className={classes.productPage}>
            <Helmet>
                <title>Bangles Making</title>
                <meta name="description" content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)." />
                <meta name="keywords" content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs" />
            </Helmet>
            <BanglesHeader
                brand="Home"
                links={<HeaderLink dropdownHoverColor="info" />}
                fixed
                color="info"
            // changeColorOnScroll={{
            //     height: 100,
            //     color: "info",
            // }}
            />
            <Parallax
                // image={require("assets/img/ban.webp").default}
                filter="dark"
                className={classes.pageHeader}
            >
                <div className={classe.container}>
                    <GridContainer justify="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classe.mlAuto,
                                classe.mrAuto,
                                classe.textCenter
                            )}
                        >
                            <h1 className={classe.title}>Making List</h1>
                            <h4 style={{ color: "white" }}>Each BANGLES.ORG piece is crafted to give it a signature finish. Yet, you get a wide range of gold plated jewellery choices with the making charges at a good price.</h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.container}>
                <h5 className={classNames(classes.title, classes.textCenter)}>We make bangles that are loved by all age group ladies because our designs are unique and never get out of fashion. The raw material we use is of fine quality and also we give 6 months colour guarantee on our bangles. As we are a manufacturer, we give bangles at factory prices (discounted rates). We give an opportunity to customize the bangles according to your choice and taste so that you feel the joy of wearing the best bangles.</h5>
            </div>
            <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                    <Dialog
                        style={{ marginTop: "10%" }}
                        classes={{
                            root: classPop.modalRoot,
                            paper: classPop.modal + " " + classPop.modalLogin,
                        }}
                        open={loginModal}
                        TransitionComponent={Transition}
                        keepMounted
                        // onClose={() => setLoginModal(false)}
                        aria-labelledby="login-modal-slide-title"
                        aria-describedby="login-modal-slide-description"
                    >
                        <DialogTitle
                            id="login-modal-slide-title"
                            disableTypography
                            className={classPop.modalHeader}
                        >
                            <h5>Loading...</h5>
                            <DialogContent
                                id="login-modal-slide-description"
                                className={classPop.modalBody}
                            >
                                <GridContainer justify="center">
                                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#00acc1" }} />
                                </GridContainer>
                                <br />
                            </DialogContent>
                        </DialogTitle>
                    </Dialog>
                    <div className={classes.relatedProducts}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={kada1} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${kada1})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={polaBangles2} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${polaBangles2})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={fourPieces3} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${fourPieces3})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={sixPieces4} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${sixPieces4})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={sevenPieces5} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${sevenPieces5})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={eightPieces6} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${eightPieces6})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={twevelPieces7} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${twevelPieces7})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                                <Card profile plain className={classes.card3}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CardHeader image plain>
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <img src={twentyFourPieces8} alt="..." />
                                                    <p style={{ position: "absolute", top: "80%", left: "50%", width: "40px", height: "40px", transform: "translate(-50%, -50%)" }}><img src={logo} alt="..." /></p>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                        backgroundImage: `url(${twentyFourPieces8})`,
                                                        opacity: "1",
                                                    }}
                                                />
                                            </CardHeader>
                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        <h3 className={classNames(classes.title, classes.textCenter)}>
                            Popular items Today:
                        </h3>

                        <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => {
                      // pltprice = pltprice + prod.plating.price
                      boxpltamt = prod.productSets.pcs * prod.productBox.box;
                      metprice = metprice + prod.productRawPrice.price;
                      metgram = prod.metalGram;
                      metamt =
                        prod.productRawPrice.price +
                        prod.productRawPrice.different;
                      boxmetgram =
                        metgram *
                        prod.productSets.pcs *
                        prod.productBox.box *
                        metamt;
                      metgramamt = metgram * metamt;
                      return (
                        <>
                          <GridItem sm={6} md={3} key={prod.id}>
                            <Card product>
                              <CardHeader image>
                                <img
                                  src={
                                    prod.productImage[0].cropImageUrl +
                                    "?id=" +
                                    prod.productImage[0].imgId
                                  }
                                  alt="..."
                                />
                                {/* <p style={{ position: "absolute", left: "90%", top: "90%", transform: "translate(-50%, -50%)" }}><Tooltip
                                                                id="tooltip-tops"
                                                                title="Share to WhatsApp"
                                                                placement="top"
                                                                classes={{ tooltip: classes.tooltip }}
                                                            ><a href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp}&text=https://bangles.org.in/bangles-product?id=${prod.id}%20${prod.banglessizemm.description}%20${prod.plating.description.slice(8)}&app_absent=0`}>
                                                                    {imgApi + "" + prod.design1.image1 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                        imgApi + "" + prod.design2.image2 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                            imgApi + "" + prod.design3.image3 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                                imgApi + "" + prod.design4.image4 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                                    imgApi + "" + prod.design5.image5 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                                        imgApi + "" + prod.design6.image6 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> : ""}
                                                                </a></Tooltip></p>

                                                            {prod.design1.img1active === 1 ? <img src={imgApi + "" + prod.design1.image1} alt="..." /> :
                                                                prod.design2.img2active === 1 ? <img src={imgApi + "" + prod.design2.image2} alt="..." /> :
                                                                    prod.design3.img3active === 1 ? <img src={imgApi + "" + prod.design3.image3} alt="..." /> :
                                                                        prod.design4.img4active === 1 ? <img src={imgApi + "" + prod.design4.image4} alt="..." /> :
                                                                            prod.design5.img5active === 1 ? <img src={imgApi + "" + prod.design5.image5} alt="..." /> :
                                                                                prod.design6.img6active === 1 ? <img src={imgApi + "" + prod.design6.image6} alt="..." /> : ""} */}

                                <p
                                  style={{
                                    position: "absolute",
                                    top: "65%",
                                    left: "50%",
                                    width: "65px",
                                    height: "65px",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <img src={logo} alt="..." />
                                  {/* {imgApi + "" + prod.design1.image1 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                    imgApi + "" + prod.design2.image2 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                        imgApi + "" + prod.design3.image3 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                            imgApi + "" + prod.design4.image4 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                                imgApi + "" + prod.design5.image5 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                                    imgApi + "" + prod.design6.image6 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> : ""} */}
                                </p>
                              </CardHeader>
                              <CardBody>
                                <h6
                                  className={classNames(
                                    classes.cardCategory,
                                    classes.textRose
                                  )}
                                >
                                  {prod.productCategory.title} <br />{" "}
                                  {prod.productParent.title}{" "}
                                  {prod.itemCodeNumber} - {prod.id}
                                  <Button
                                    round
                                    color="info"
                                    onClick={(event) => {
                                      setAnchorElBottom(event.currentTarget);
                                      setProdDetails(prod);
                                      setProdDetailsChild(
                                        prod.productChild.map((lbr) => {
                                          return (
                                            <>
                                              <span>
                                                {lbr.title + " " + lbr.price}
                                              </span>
                                              <br />
                                            </>
                                          );
                                        })
                                      );
                                      setProdDetailsChild1(
                                        prod.productChild
                                          .reduce((a, b) => a + b.price, 0)
                                          .toFixed(2)
                                      );
                                      setProdDetailsChild2(
                                        prod.productChild
                                          .reduce(
                                            (a, b) =>
                                              a +
                                              b.price *
                                                prod.productSets.pcs *
                                                prod.productBox.box,
                                            0
                                          )
                                          .toFixed(2) + "/-"
                                      );
                                      setProdDetailsRaw(prod.productRawPrice);
                                      setProdDetailsSets(prod.productSets);
                                      setProdDetailsBox(prod.productBox);
                                    }}
                                  >
                                    More Details
                                  </Button>
                                </h6>
                                {/* <h4 className={classes.cardTitle}>{prod.description}</h4> */}
                              </CardBody>
                              {/* <CardFooter
                                className={classes.justifyContentBetween}
                              >
                                <div className={classes.price}>
                                  {prod.productChild.map((lbr) => {
                                    return (
                                      <>
                                        <span>
                                          {lbr.title + " " + lbr.price}
                                        </span>
                                        <br />
                                      </>
                                    );
                                  })}
                                  <span style={{ color: "red" }}>
                                    Labour/Box.{" "}
                                    {prod.productChild
                                      .reduce((a, b) => a + b.price, 0)
                                      .toFixed(2)}
                                    {"/"}
                                    {prod.productChild
                                      .reduce(
                                        (a, b) =>
                                          a +
                                          b.price *
                                            prod.productSets.pcs *
                                            prod.productBox.box,
                                        0
                                      )
                                      .toFixed(2) + "/-"}
                                  </span>
                                  <br />
                                  <span>
                                    Metal Price {prod.productRawPrice.price} +{" "}
                                    {prod.productRawPrice.different} = {metamt}{" "}
                                    <br />
                                    Metal Gram {prod.metalGram} <br />
                                    Ready Gram {prod.readyGram}
                                  </span>
                                  <br />
                                  <div className={classes.cardDescriptions}>
                                    {(
                                      prod.readyGram * prod.productSets.pcs
                                    ).toFixed(4)}{" "}
                                    Avg Gram Of {prod.productSets.setDesc}{" "}
                                    {prod.productBox.boxDesc}
                                  </div>{" "}
                                  <span style={{ color: "red" }}>
                                    Metal Amount{" "}
                                    {metgramamt.toFixed(2) +
                                      "/" +
                                      boxmetgram.toFixed(2)}
                                    /-
                                  </span>
                                </div>
                              </CardFooter> */}
                            </Card>
                          </GridItem>
                        </>
                      );
                    })}
                  <Popover
                    classes={{
                      paper: classPop.popover,
                    }}
                    open={Boolean(anchorElBottom)}
                    anchorEl={anchorElBottom}
                    onClose={() => setAnchorElBottom(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classPop.popoverBody}>
                      <span style={{ color: "yellow" }}>
                        Product Id: {prodDetails.id}
                      </span>
                      <br />
                      {prodDetailsChild}
                      <span style={{ color: "yellow" }}>
                        Labour/Box. {prodDetailsChild1}
                        {"/"}
                        {prodDetailsChild2}
                      </span>
                      <br />
                      <span>
                        Metal Price {prodDetailsRaw.price} +{" "}
                        {prodDetailsRaw.different} = {metamt} <br />
                        Metal Gram {prodDetails.metalGram} <br />
                        Ready Gram {prodDetails.readyGram}
                      </span>
                      <br />
                      <div className={classes.cardDescriptions}>
                        {(prodDetails.readyGram * prodDetailsSets.pcs).toFixed(
                          4
                        )}{" "}
                        Avg Gram Of {prodDetailsSets.setDesc}{" "}
                        {prodDetailsBox.boxDesc}
                      </div>{" "}
                      <span style={{ color: "yellow" }}>
                        Metal Amount{" "}
                        {metgramamt.toFixed(2) + "/" + boxmetgram.toFixed(2)}
                        /-
                      </span>
                    </div>
                  </Popover>
                </GridContainer>
              </div>
            </InfiniteScroll>

                    </div>
                    <GridItem
                        md={3}
                        sm={3}
                        className={classNames(classes.mlAuto, classes.mrAuto)}
                    >
                        <Button color="info" round>Loading...</Button>
                    </GridItem>
                </div>
            </div>
            <BanglesFooter />
        </div>
    );
}
