/* eslint-disable */
import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import Bangles from "assets/img/assets-for-demo/sections/1MM-Gold-plated-bangles.jpg";
import BanglesImage from "views/BanglesPage/Section/BanglesImage.js"



const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <Helmet>
        {/* <title>Bangles-Category</title> */}
        <meta name="description" content="Bangles at very affordable low prices" />
        <meta name="keywords" content="Golwire Small, Golwire Medium, 1MM Gold Plated bangles, 1.5MM Gold Plated bangles, 2MM Gold Plated bangles, 2.5MM Gold Plated bangles, 3MM Tri Color bangles, 3.5MM Micro Gold Plated bangles, 4MM Gold Plated bangles, 4.5MM Gold Plated bangles, 5MM Gold Plated bangles, 5.2MM Gold Plated bangles, 5/3MM Odisha Pola bangles, 5.5MM Gold Plated bangles, 6MM 7 Semanario bangles, 6.5MM Gold Plated bangles, 7MM 7 Semanario bangles, 7.2MM 7 Semanario bangles, 7/3MM Gold Plated bangles, 7.8MM Micro Gold Plated bangles, 8MM 3 Tone Plated bangles, 8/3MM Gold Plated bangles, 9MM Gold Plated bangles, 9.2MM Gold Plated bangles, 9/3MM Gold Plated bangles, 10MM Gold Plated bangles, 11MM 3 Tone bangles, 11/3MM 3 Tone Plated bangles, 11/4MM Tri Color bangles, 11/5MM 3 Tone Plated bangles, 12MM Gold Plated bangles, 12/3MM Gold Plated bangles, 15MM Silver & Gold punjabi kada, 19MM Gold Plated bangles, 20MM Tricolor bangles, 20/3MM Tricolor bangles, 12MM Silver plated punjabi kada, 12MM Gold plated punjabi kada." />
      </Helmet>
      {/* Project 1 START */}
      {/* <div className={classes.projects}> */}
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>
              Bangles with the Best Standard
            </h2>
            <h5 className={classes.description}>
              We are one of the top manufacturers and retailers in India, having hundreds of satisfied and happy clients from India and around the globe. We manufacture bangles of different models, sizes, and colors like Micro Gold Plated, Rose Gold Plated, silver plated, 2 Tone & 3 Tone 7 Seminario Bangles at very affordable low prices.
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {BanglesImage.map((x) => (
            <GridItem xs={12} sm={6} md={3}>
              <Card
                raised
                background
                style={{ backgroundImage: `url(${x.img})` }}
              >
                <CardBody background>
                  {/* <h6 className={classes.cardCategory}>PRODUCTIVITY</h6> */}
                  <a href={x.link}>
                    <h4 className={classes.cardTitleWhite}>
                      {/* The Best Productivity */}
                      {x.prodHead}
                    </h4>
                  </a>
                  <p className={classes.cardDescription}>
                    {x.desp}
                  </p>
                  <a className={classes.cardTitleWhite} href={x.link}>Read More</a>
                  {/* <Button round color="danger"> */}
                  {/* <Icon>content_copy</Icon>  */}
                  {/* <AiOutlineEye /> Product */}
                  {/* </Button> */}
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
      {/* </div> */}
      {/* Project 1 END */}
    </div>
  );
}
