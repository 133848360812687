import React from "react";
import { Helmet } from "react-helmet";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";
import macbookImage from "assets/img/assets-for-demo/presentationViewSectionComponent/laptop-basics.png";
import shoppingCartImage from "assets/img/assets-for-demo/presentationViewSectionComponent/table.jpg";
import shareButtonImage from "assets/img/assets-for-demo/presentationViewSectionComponent/share-btn.jpg";
import cardImage from "assets/img/assets-for-demo/presentationViewSectionComponent/Shirt5.jpeg";
import twitterImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card.jpg";
import iconsImage from "assets/img/assets-for-demo/presentationViewSectionComponent/social-row.jpg";
import repostImage from "assets/img/assets-for-demo/presentationViewSectionComponent/pin-btn.jpg";

const useStyles = makeStyles(componentsStyle);

export default function SectionComponents() {
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        {/* <title>Bangles Component</title> */}
        <meta name="description" content="Imitation jewellery is also in trend Nowadays and so are gold bangles" />
        <meta name="keywords" content="Best original bangles" />
      </Helmet>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={6} lg={5} sm={12} xs={12}>
            <h3 className={classes.title}>Bangles, women love to wear</h3>
            {/* <h6 className={classes.description}>
              The core elements of your website
            </h6> */}
            <h5 className={classes.description}>
            Beside original bangles, imitation jewellery is also in trend nowadays and so are gold plated bangles. The trendy gold plating bangles gives the ethnic look to your attire and makes you look graceful. Gold plated bangles are appropriate for any occasion and any party at any time of day. Women prefer gold plated jewellery because it is pocket friendly, less expensive and looks presentable. Imitation jewellery like Gold plated bangles comes in various designs and style also in various patterns. Gold plated bangles are best preferred by women for wedding purposes and are popular among brides for bridal jewellery.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}  className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                src={macbookImage}
                alt="macbook"
                className={classes.componentsMacbook}
              />
              <img
                src={shoppingCartImage}
                alt="macbook"
                className={classes.shoppingCart}
              />
              {/* <a href="https://inshabangles.com/"></a> */}
              <img
                src={shareButtonImage}
                alt="macbook"
                className={classes.shareButton}
              />
             
              <img
                src={cardImage}
                alt="macbook"
                className={classes.cardImage}
              />
              <img
                src={twitterImage}
                alt="macbook"
                className={classes.twitterImage}
              />
              <img
                src={iconsImage}
                alt="macbook"
                className={classes.iconsImage}
              />
              <img
                src={repostImage}
                alt="macbook"
                className={classes.repostImage}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
