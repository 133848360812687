const BanglesImage = [
  {
    id: 1,
    img: "Img/jeans1.jpeg",
    desp: "Bangles crafted with care and love that add elegance to your personality",
    prodHead: "Golwire Small",
    // link: "bangles-product?id=103&catdesc=Bangles&cat1desc=Golwire%20Small&Plating=Gold%20Plating"
  },
  {
    id: 2,
    img: "Img/jeans2.jpeg",
    desp: "We design jewelry according to your demands and requirements",
    prodHead: "Golwire Medium",
  },
  {
    id: 3,
    img: "Img/jeans3.jpeg",
    desp: "Our designs never go out of fashion; our work will exceed your expectation.",
    prodHead: "1MM Gold Plated",
  },
  {
    id: 4,
    img: "Img/jeans4.jpeg",
    desp: "Come look at them, because there is no harm in looking",
    prodHead: "1.5MM Gold Plated",
  },
  {
    id: 5,
    img: "Img/jeans5.jpeg",
    desp: "Kings of the bangle world, Finally a place you have looking for",
    prodHead: "2MM Gold",
  },
  {
    id: 6,
    img: "Img/jeans6.jpeg",
    desp: "Purchase with affordability and Walk with the trend and design",
    prodHead: "2.5MM Gold Plated",
  },
  {
    id: 7,
    img: "Img/jeans7.jpeg",
    desp: "The design may change, choose with your range",
    prodHead: "3MM Tri Color",
  },
  {
    id: 8,
    img: "Img/jeans8.jpeg",
    desp: "Precision in every inch of our work, Fashion and style in everything we make.",
    prodHead: "3.5MM Micro Gold Plated",
  },
  {
    id: 9,
    img: "Img/jeans9.jpeg",
    desp: "Come if you want something better, because we never compromise with quality.",
    prodHead: "4MM Gold Plated",
  },
  {
    id: 10,
    img: "Img/jeans10.jpeg",
    desp: "Stunning design with Refreshing Ideas that you can’t ignore.",
    prodHead: "4.5MM Gold Plated",
  },
  {
    id: 11,
    img: "Img/jeans11.jpeg",
    desp: "Bangles made from our hearts to your soul, you will never forget what you see here",
    prodHead: "5MM Gold Plated",
  },
  {
    id: 12,
    img: "Img/jeans12.jpeg",
    desp: "Enjoy the convenience of buying bangles with us",
    prodHead: "5.2MM Gold Plated",
  },
  {
    id: 13,
    img: "Img/jeans13.jpeg",
    desp: "Come if want honesty, perfection, and Creative Designs",
    prodHead: "5/3MM Odisha Pola",
  },
  {
    id: 14,
    img: "Img/Shirt1.jpeg",
    desp: "Choose Comfortable refreshing Ideas according to your wish",
    prodHead: "5.5MM Gold Plated",
  },
  {
    id: 15,
    img: "Img/Shirt2.jpeg",
    desp: "Get a perfect match, Find the ‘Better’ out of ‘Best’",
    prodHead: "6MM 7 Semanario",
  },
  {
    id: 16,
    img: "Img/Shirt3.jpeg",
    desp: "The true beauty of bangles is due to their naturally customizable design",
    prodHead: "6.5MM Gold Plated",
  },
  {
    id: 17,
    img: "Img/Shirt4.jpeg",
    desp: "The fashion that never gets old, Wear at the time of ",
    prodHead: "7MM 7 Semanario",
  },
  {
    id: 18,
    img: "Img/Shirt5.jpeg",
    desp: "Accurate designs just the way you wanted for special occasions",
    prodHead: "7.2MM 7 Semanario",
  },
  {
    id: 19,
    img: "Img/Shirt6.jpeg",
    desp: "Feel the difference by wearing our bangles, and Take a moment to praise our elegant collection.",
    prodHead: "7/3MM Gold Plated",
  },
  {
    id: 20,
    img: "Img/Shirt7.jpeg",
    desp: "Look as beautiful outside as you are within. Look elegant and shine bright.",
    prodHead: "7.8MM Micro Gold Plated",
  },
  {
    id: 21,
    img: "Img/Shirt8.jpeg",
    desp: "A solution to your gold and silver desire.",
    prodHead: "8MM 3 Tone Plated",
  },
  {
    id: 22,
    img: "Img/Shirt9.jpeg",
    desp: "We provide satisfaction with our bangles. If you can’t stop thinking about it, buy it",
    prodHead: "8/3MM Gold Plated",
  },
  {
    id: 23,
    img: "Img/Shirt10.jpeg",
    desp: "A bangle has the power to be this one little thing that can make you feel unique.",
    prodHead: "9MM Gold Plated",
  },
  {
    id: 24,
    img: "Img/Shirt11.jpeg",
    desp: "Sparkling with beauty, shining with pride, captivating charm",
    prodHead: "9.2MM Gold Plated",
  },
  {
    id: 25,
    img: "Img/Shirt12.jpeg",
    desp: "An Add-on to your impression, Something Shiny. Something Beautiful.",
    prodHead: "9/3MM Gold Plated",
  },
  {
    id: 26,
    img: "Img/Shirt13.jpeg",
    desp: "Do not limit your style, buy bangles and Enjoy the moment of ethnic beauty",
    prodHead: "10MM Gold Plated",
  },
];

export default BanglesImage;
