/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";
// import { imgApi } from "utils/imgApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Slide from "@material-ui/core/Slide";
// import Tooltip from "@material-ui/core/Tooltip";
// import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Table from "components/Table/Tables.js";
import Tables from "components/Table/Table.js";
import BanglesHeader from "views/BanglesPage/Section/BanglesHeader";
import BanglesHeaderLink from "views/BanglesPage/Section/BanglesHeaderLink";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(profilePageStyle);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyl = makeStyles(aboutUsStyle);

export default function MyOrder({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  // const [userId, setUserId] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const classes = useStyles();
  const history = useHistory();

  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeTitel = useStyl();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const current = new Date();
  const dateCurrent = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  current.setDate(current.getDate() + 10);
  let dateEx = new Date(current).toLocaleDateString("de");

  async function getList() {
    const response = await fetch(localApi + `/clientGroup/allList`);
    const json = await response.json();
    const regClient = json.ClientList.filter((f) => f.regId == userObj.id)[0];
    // console.log(regClient);

    const getList = await fetch(
      localApi +
        `/orders/list/byAdminAndClientId/${userObj.admin.id}/${regClient.id}`
    );
    const res = await getList.json();
    // console.log(res);
    if (res.error === "true") {
      setLoad(false);
      history.push("/error-page");
    } else {
      setLoad(true);
      // setAddCart(res.OrderData)
      setAddCart(res && res.length != 0 ? res : history.push("/error-page"));
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <Helmet>
        <title>My Order</title>
      </Helmet>
      {load ? (
        <>
          <BanglesHeader
            color="info"
            brand="Home"
            links={<BanglesHeaderLink dropdownHoverColor="info" />}
            fixed
            // changeColorOnScroll={{
            //     height: 200,
            //     color: "info",
            // }}
            {...rest}
          />
          <Parallax
            image={require("assets/img/ban.webp").default}
            filter="dark"
            className={classes.parallax}
          >
            <div className={classeTitel.container}>
              <GridContainer justify="center">
                <GridItem
                  md={8}
                  sm={8}
                  className={classNames(
                    classeTitel.mlAuto,
                    classeTitel.mrAuto,
                    classeTitel.textCenter
                  )}
                >
                  <h1 className={classeTitel.title}>ORDER</h1>
                  <h4></h4>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classeCart.main, classeCart.mainRaised)}>
            <div className={classeCart.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {" "}
                  <h3 className={classeCart.cardTitle}>My Order</h3>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.mrAuto + " " + classes.mlAuto}
                >
                  <Tables
                    tableHead={[
                      "SI.No",
                      "Order Id",
                      "Date",
                      "Units",
                      "Amount",
                      "Balance",
                      "Status",
                      "View",
                      "Payment",
                    ]}
                    tableData={addCart.map((c, index) => [
                      <span>{index + 1}</span>,
                      <span>{c.id}</span>,
                      <span>{c.createDate.slice(0, -19)}</span>,
                      <span>{c.totalBox}</span>,
                      <span>₹ {Math.round(c.amount) + "/-"}</span>,
                      <span>₹ {Math.round(c.balance) + "/-"}</span>,
                      <span>
                        {(
                          (c.payment.reduce((a, b) => a + b.received, 0) /
                            c.amount) *
                          100
                        ).toFixed(2)}
                        %
                      </span>,
                      <a href={`/order-view?id=${c.id}&client=${c.clientId}`}>
                        <VisibilityIcon />
                      </a>,
                      <a
                        href={`/payment-recieved?id=${c.id}&client=${c.clientId}`}
                      >
                        <CreditCardIcon />
                      </a>,
                    ])}
                  />
                  <br />
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <BanglesFooter />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
